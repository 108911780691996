import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, Typography, TextField, Button, useTheme } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { DECIMALS } from "config/constants"
import Switch from '@mui/material/Switch';
import AzeroImg from "components/AzeroImg";
import TypingEffect from "components/TypingEffect";
import CustomSwitch from "components/CustomSwitch";
import { StakeButton, StakeInfo, StakeTextField } from "components/staking";
import { toast } from "react-hot-toast";


class AccountInfo {
  nonce = 0
  consumers = 0
  providers = 0
  sufficients = 0
  data = {
    free: 0,
    reserved: 0,
    miscFrozen: 0,
    feeFrozen: 0,
  }
}

export const StakeNominator = ({
  api,
  account,
  isConnected,
  transferable,
  balance,
  stakedBalanceNominator,
  payeeNominator,
  injector,
  calculateAllBalances,
  isWithdrawUnbonded,
  amountToWithdraw,
}) => {
  const isNonMobileScreens = useMediaQuery("(min-width:600px)");
  const widthAmountBox = isNonMobileScreens ? "12rem" : "5rem"

  const [stakingLoadingNominator, setStakingLoadingNominator] = useState(false);
  const [stakingAmountNominator, setStakingAmountNominator] = useState("");
  const [ustakingLoadingNominator, setUnstakingLoadingNominator] = useState(false);
  const [unstakingAmountNominator, setUnstakingAmountNominator] = useState("");
  const [withdrawLoadingNominator, setWithdrawLoadingNominator] = useState(false);
  const [restakingSwitch, setRestakingSwitch] = useState(false);

  const { palette } = useTheme();
  const hooverBackground = palette.connectButton.hooverBackground;
  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;
  const textFIeld = palette.widgets.textFIeld;


  const handleChangeRestakingSwitch = async () => {
    console.log("handleChangeRestakingSwitch")
    let payee;
    if (payeeNominator === "Stash") {
      payee = "Staked"
    }
    if (payeeNominator === "Staked") {
      payee = "Stash"
    }

    const tx = await api.tx.staking.setPayee(payee).signAndSend(account.address, { signer: injector.signer });
    console.log("tx: ", tx.toString())

    await new Promise(r => setTimeout(r, 3000));
    await calculateAllBalances()

  }

  const handleStakeButtonNominator = async () => {

    if (transferable < Number(stakingAmountNominator)) {
      toast.error("The amount you want to stake is greater than your available balance.");
      return;
    }

    if (transferable - Number(stakingAmountNominator) < 0.01) {
      toast.error("Your balance is not enough to pay the transaction signing fees.");
      return;
    }

    setStakingLoadingNominator(true)

    if (stakingAmountNominator === "0" || stakingAmountNominator === "") {
      toast.error("It is not possible stake 0 AZERO");
      setStakingLoadingNominator(false)
      return
    }

    if (Number(stakingAmountNominator) < 0) {
      toast.error("It is not possible stake a negative number");
      setStakingLoadingNominator(false)
      return
    }

    try {
      // Bond more funds if account is a nominator
      const tx = await api.tx.staking.bondExtra(BigInt(Number(stakingAmountNominator) * DECIMALS)).signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      await calculateAllBalances()
      setStakingAmountNominator("")

    } catch (err) {
      console.log("Error when bonding: ", err)
      setStakingLoadingNominator(false)
      setStakingAmountNominator("")
    }
    setStakingLoadingNominator(false)
  }

  const handleUnstakeButtonNominator = async () => {

    if (transferable < 0.01) {
      toast.error("Your balance is not enough to pay the transaction signing fees.");
      return;
    }

    setUnstakingLoadingNominator(true)

    if (unstakingAmountNominator === "0" || unstakingAmountNominator === "") {
      toast.error("It is not possible unstake 0 AZERO");
      setUnstakingLoadingNominator(false)
      return
    }

    if (Number(unstakingAmountNominator) < 0) {
      toast.error("It is not possible unstake a negative number");
      setUnstakingLoadingNominator(false)
      return
    }

    try {
      // Bond more funds if account is a nominator
      const tx = await api.tx.staking.unbond(BigInt(Number(unstakingAmountNominator) * DECIMALS)).signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      await calculateAllBalances()
      setUnstakingAmountNominator("")

    } catch (err) {
      console.log("Error when bonding: ", err)
      setUnstakingLoadingNominator(false)
      setUnstakingAmountNominator("")
    }
    setUnstakingLoadingNominator(false)
  }

  const handleWithdrawButtonNominator = async () => {

    if (transferable < 0.01) {
      toast.error("Your balance is not enough to pay the transaction signing fees.");
      return;
    }

    setWithdrawLoadingNominator(true)

    try {
      // Unbond whitdrawable funds
      const tx = await api.tx.staking.withdrawUnbonded(0).signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      await calculateAllBalances()

    } catch (err) {
      console.log("Error when bonding: ", err)
      setWithdrawLoadingNominator(false)
    }
    setWithdrawLoadingNominator(false)
  }



  useEffect(() => {
    if (isConnected) {
      if (payeeNominator === "Stash") setRestakingSwitch(false);
      if (payeeNominator === "Staked") setRestakingSwitch(true)
    }
  }, [payeeNominator]);


  return (
    <Box aria-label="StakeNominator">
      <WidgetWrapper backgroundColor={background} borderColor={border}>

        <FlexBetween pb="10px " color={colorTittle} fontSize={isNonMobileScreens ? "24px" : "12px"} sx={{ fontWeight: "bold", justifyContent: "left" }}>
          <TypingEffect text="NOMINATOR" typingSpeed={200} />
        </FlexBetween>

        {/* STAKE  */}
        <FlexBetween p={isNonMobileScreens ? "10px" : ""} gap="10px" sx={{ display: "flex", alignItems: "end" }}>

          <StakeTextField
            text={"Amount to stake:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={stakingAmountNominator}
            setStakingAmountPool={setStakingAmountNominator}
          />

          <StakeButton
            text={"Stake"}
            stakingLoading={stakingLoadingNominator}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleStakeButtonNominator}
          />

        </FlexBetween>

        {/* UNSTAKE  */}
        <FlexBetween p={isNonMobileScreens ? "10px" : ""} gap="10px" sx={{ display: "flex", alignItems: "end" }}>

          <StakeTextField
            text={"Amount to unstake:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={unstakingAmountNominator}
            setStakingAmountPool={setUnstakingAmountNominator}
          />

          <StakeButton
            text={"Unstake"}
            stakingLoading={ustakingLoadingNominator}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleUnstakeButtonNominator}
          />

        </FlexBetween>

        {/* WITHDRAW UNBONDED  */}
        {isWithdrawUnbonded &&
          <FlexBetween p={isNonMobileScreens ? "10px" : "10px 0"}>
            <Typography color={colorBalance} fontSize={isNonMobileScreens ? "10px" : "7px"} >
              Withdraw available: <span style={{ fontWeight: "900" }}>{amountToWithdraw}</span>
              <AzeroImg width={isNonMobileScreens ? "11px" : "8px"} isNonSmallMobileScreens={isNonMobileScreens} />
            </Typography>
            {withdrawLoadingNominator === true ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                size={isNonMobileScreens ? "large" : "small"}
                sx={{ backgroundColor: textFIeld, color: colorBalance }}
              >
                <Typography fontWeight={"900"} fontSize={isNonMobileScreens ? "12px" : "8px"}>Withdraw</Typography>
              </LoadingButton>
            ) : (
              <>
                <Button
                  variant="contained"
                  onClick={handleWithdrawButtonNominator}
                  disabled={withdrawLoadingNominator}
                  size={isNonMobileScreens ? "large" : "small"}
                  sx={{ backgroundColor: textFIeld, color: colorBalance }}
                >
                  <Typography fontWeight={"900"} fontSize={isNonMobileScreens ? "12px" : "8px"}>Withdraw</Typography>
                </Button>
              </>
            )}
          </FlexBetween>
        }

        <FlexBetween p="10px" sx={{ justifyContent: "left" }}>
          <Typography fontSize={isNonMobileScreens ? "14px" : "8px"} color={colorBalance}>Re-staking</Typography>
          <Box pl={"10px"}>
            <CustomSwitch
              isNonMobileScreens={isNonMobileScreens}
              restakingSwitch={restakingSwitch}
              handleChangeRestakingSwitch={handleChangeRestakingSwitch}
            />
          </Box>
        </FlexBetween >

      </WidgetWrapper>
    </Box>
  );
};
