import React from "react";
import { Typography } from "@mui/material";
import PropTypes from 'prop-types'

import FlexBetween from "components/FlexBetween";
import AzeroImg from "components/AzeroImg";


export const StakeInfo = ({ padding = "", isNonSmallMobileScreens, text, lettersColor, numbersColor, mobileFontSize, amount }) => {

  return (
    <FlexBetween p={padding}>
      <FlexBetween>
        <Typography color={lettersColor} fontSize={!isNonSmallMobileScreens && mobileFontSize}>{text} &nbsp;</Typography>
        <Typography color={numbersColor} fontSize={!isNonSmallMobileScreens && mobileFontSize}>
          {amount}
          <AzeroImg width={isNonSmallMobileScreens ? "13px" : "8px"} isNonSmallMobileScreens={isNonSmallMobileScreens} />
        </Typography>
      </FlexBetween>
    </FlexBetween>

  )
}

StakeInfo.propTypes = {
  isNonSmallMobileScreens: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  lettersColor: PropTypes.string.isRequired,
  numbersColor: PropTypes.string.isRequired,
  mobileFontSize: PropTypes.string.isRequired,
  // amount: PropTypes.number.isRequired,
}

