import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, Typography, TextField, Button, useTheme, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from "@mui/material";
import { toast } from "react-hot-toast";

import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { DECIMALS } from "config/constants"
import AzeroImg from "components/AzeroImg";
import { StakeButton, StakeTextField } from "components/staking";
import TypingEffect from "components/TypingEffect";
import DeleteIcon from '@mui/icons-material/Delete';
import { CodePromise, ContractPromise } from '@polkadot/api-contract'
import abiDataJson from '../../smart-contracts/testnet/profits_saver.json'
import CustomSwitch from "components/CustomSwitch";

export const Profits = ({
  api,
  account,
  isConnected,
  injector,
  transferable,
  azeroPrice,
  isNonSmallMobileScreens,
  isMainnet,
}) => {
  const isNonMobileScreens = useMediaQuery("(min-width:600px)");

  const [azeroAmountInput, setAzeroAmountInput] = useState("");
  const [priceAmountInput, setPriceAmountInput] = useState("");
  const [blockchainSaveLoading, setBlockchainSaveLoading] = useState(false);
  const [buyList, setBuyList] = useState([]);
  const [hide, setHide] = useState(false);
  const [totalProfit, setTotalProfit] = useState("");
  const [averageBuyPrice, setAverageBuyPrice] = useState("");
  const [totalAzero, setTotalAzero] = useState("");
  const [buySellSwitch, setBuySellSwitch] = useState("buy");

  const { palette } = useTheme();
  const hooverBackground = palette.connectButton.hooverBackground;
  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;
  const textFIeld = palette.widgets.textFIeld;

  let contractAddress;
  if (isMainnet) contractAddress = "5GDi3BS6tWwWBDpZcmdUxuMS9TFtAo4yPhyrYejqMrUdYudZ" // testnet
  else contractAddress = "5DzsJhuTBydiKVAyaeEyxRqDriXi8JjYwb2TJBQ5Xi18hkTt" // mainnet

  const contract = new ContractPromise(api, JSON.stringify(abiDataJson), contractAddress);

  const getSavedProfitsByAccount = async () => {

    const gasLimit = api.registry.createType('WeightV2', { refTime: 3912368128, proofSize: 131072 });
    const { gasRequired, storageDeposit, result, output } = await contract.query.getProfits(
      account.address,
      {
        gasLimit,
        storageDepositLimit: null
      }
    );

    if (result.isOk) {
      const profits = JSON.parse(output.toString()).ok;

      for (let i = 0; i < profits.azeroAmount.length; i++) {
        setBuyList((prevBuyList) => [...prevBuyList, { id: i, txTypeIsBuy: profits.txTypeIsBuy[i], azeroAmount: profits.azeroAmount[i] / 1000000, price: profits.price[i] / 1000000 }]);
      }

    } else {
      console.error('Error', result.asErr);
    }
  };


  const pushProfits = async () => {

    let txTypeIsBuy = [];
    let azeroAmount = [];
    let price = [];
    for (let i = 0; i < buyList.length; i++) {
      txTypeIsBuy.push(buyList[i].txTypeIsBuy)
      azeroAmount.push(Math.floor(Number(buyList[i].azeroAmount) * 1000000))
      price.push(Math.floor(Number(buyList[i].price) * 1000000))
    }

    const gasLimit = api.registry.createType('WeightV2', { refTime: 3912368128, proofSize: 131072 });
    // await contract.tx.saveProfitsPush(
    await contract.tx.saveProfitsReplace(
      {
        gasLimit,
        storageDepositLimit: null,
      },
      txTypeIsBuy, azeroAmount, price
    ).signAndSend(account.address, { signer: injector.signer }, (result) => {
      console.log('signAndSend status:', result.status.toString());
      if (result.status.isInBlock) {
        if (result.dispatchError !== undefined) {
          console.log('signAndSend dispatchError:', result.dispatchError);
          console.log('signAndSend dispatchInfo:', result.dispatchInfo);
        } else {
          console.log('signAndSend result:', result.toHuman());
          console.log('signAndSend gasConsumed:', result.dispatchInfo.weight.toHuman());
        }
      }
      if (result.status.isFinalized) {
        console.log("TX finished!!")
        toast.success("Profits saves in the blockchain successfully");
      }
    });

  }


  const handleDeleteTodo = (id) => {
    setBuyList((prevBuyList) => prevBuyList.filter((todo) => todo.id !== id));
  };

  const handleToggleHide = () => {
    setHide((prevHide) => !prevHide);
  };


  const handleSaveLocalButton = async () => {

    if (azeroAmountInput === "0" || azeroAmountInput === "") {
      toast.error("Please enter the amount of azero");
      return
    }
    if (Number(azeroAmountInput) < 0) {
      toast.error("It is not possible to enter an azero negative amount");
      return
    }
    if (priceAmountInput === "0" || priceAmountInput === "") {
      toast.error("Please enter the price");
      return
    }
    if (Number(priceAmountInput) < 0) {
      toast.error("It is not possible to enter a price negative amount");
      return
    }

    setBuyList((prevBuyList) => [
      ...prevBuyList,
      {
        id: prevBuyList.length,
        txTypeIsBuy: buySellSwitch === "buy",
        azeroAmount: azeroAmountInput,
        price: priceAmountInput
      }]);
    setAzeroAmountInput("")
    setPriceAmountInput("")
  }


  const handleSaveInBlockchainButton = async () => {
    setBlockchainSaveLoading(true)

    if (!isConnected) return;

    try {
      await pushProfits()
    } catch (err) {
      console.log("Error when bonding: ", err)
      toast.error("Transaction failed")
      setBlockchainSaveLoading(false)
    }
    setBlockchainSaveLoading(false)
  }

  const calculateShowedInfo = async () => {
    if (buyList.length === 0) {
      setTotalProfit("")
      setTotalAzero("")
      setAverageBuyPrice("")
      return
    }

    let totalAzero = 0
    buyList.map(function (buyOrder, i) {
      if (buyOrder.txTypeIsBuy === true) totalAzero += Number(buyOrder.azeroAmount)
      if (buyOrder.txTypeIsBuy === false) totalAzero -= Number(buyOrder.azeroAmount)

    })
    setTotalAzero(totalAzero.toFixed(2).replace(/[.,]00$/, ""))


    let averageBuyPrice = 0
    let totalAzeroBuy = 0
    buyList.map(function (buyOrder, i) {
      if (buyOrder.txTypeIsBuy === true) {
        averageBuyPrice += Number(buyOrder.azeroAmount) * Number(buyOrder.price)
        totalAzeroBuy += Number(buyOrder.azeroAmount)
      }
    })
    if (totalAzeroBuy === 0) averageBuyPrice = 0
    else averageBuyPrice = averageBuyPrice / totalAzeroBuy

    setAverageBuyPrice(averageBuyPrice.toFixed(2).replace(/[.,]00$/, ""))


    let sellProfit = 0
    let sellAzero = 0
    buyList.map(function (buyOrder, i) {
      if (buyOrder.txTypeIsBuy === false) {
        sellProfit += Number(buyOrder.azeroAmount) * (buyOrder.price - averageBuyPrice)
        sellAzero += Number(buyOrder.azeroAmount)
      }
    })

    const totalProfit = sellProfit + totalAzero * (azeroPrice - averageBuyPrice)
    setTotalProfit(totalProfit.toFixed(2).replace(/[.,]00$/, ""))


    // let totalProfit = 0
    // let sellProfit = 0
    // buyList.map(function (buyOrder, i) {
    //   if (buyOrder.txTypeIsBuy === true) {
    //     totalProfit += Number(buyOrder.azeroAmount) * (azeroPrice - Number(buyOrder.price))
    //   }
    //   else {
    //     totalProfit -= Number(buyOrder.azeroAmount) * (azeroPrice - Number(buyOrder.price))
    //   }
    // })
    // setTotalProfit(totalProfit.toFixed(2).replace(/[.,]00$/, ""))


  }

  const handleBuySellSwitch = async () => {
    if (buySellSwitch === "buy") setBuySellSwitch("sell")
    if (buySellSwitch === "sell") setBuySellSwitch("buy")
  }


  useEffect(() => {
    setBuyList([])
    getSavedProfitsByAccount()
  }, [])

  useEffect(() => {
    if (azeroPrice !== 0) calculateShowedInfo()
  }, [buyList, azeroPrice])



  return (
    <Box aria-label="Profits">
      <WidgetWrapper sx={{ backgroundColor: background }} borderColor={border}>

        <FlexBetween pb="10px" color={colorTittle} fontSize={isNonMobileScreens ? "24px" : "12px"} sx={{ fontWeight: "bold", justifyContent: "left" }}>
          <TypingEffect text="CALCULATOR" typingSpeed={200} />
        </FlexBetween>

        {totalProfit.length !== 0 &&
          <>
            <FlexBetween pb="10px">
              <Box display="felx" sx={{ justifyContent: "left" }}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={colorMain}
                >
                  Azero Holding:&nbsp;&nbsp;&nbsp;
                </Typography>
                <Typography
                  fontSize={"20px"}
                  fontWeight={"900"}
                  color={colorBalance}
                >
                  {totalAzero}<AzeroImg margin={"-10px 0.5px 0 3px"} width={isNonSmallMobileScreens ? "14px" : "8px"} isNonSmallMobileScreens={isNonSmallMobileScreens} />
                </Typography>
              </Box>
              <Box display="felx" sx={{ justifyContent: "left" }}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={colorMain}
                >
                  Profit/Loss
                </Typography>
                <Typography
                  align="right"
                  fontSize={"20px"}
                  fontWeight={"900"}
                  color={Number(totalProfit) >= 0 ? "green" : "red"}
                >
                  {"$ " + totalProfit}
                </Typography>
              </Box>

            </FlexBetween>

            <FlexBetween pb="10px">
              <Box />
              <Box display="felx" sx={{ justifyContent: "end" }}>
                <Typography
                  align="right"
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={colorMain}
                >
                  Avg. buy price
                </Typography>
                <Typography
                  align="right"
                  fontSize={"20px"}
                  fontWeight={"900"}
                  color={colorBalance}
                >
                  {"$ " + averageBuyPrice}
                </Typography>
              </Box>

            </FlexBetween>
          </>


        }

        <FlexBetween p={isNonMobileScreens ? "10px" : ""} gap="10px" sx={{ display: "flex", alignItems: "end" }}>

          <StakeTextField
            text={"Azero amount:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={azeroAmountInput}
            setStakingAmountPool={setAzeroAmountInput}
          />

          <StakeTextField
            text={"Price:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={priceAmountInput}
            setStakingAmountPool={setPriceAmountInput}
          />

          <FlexBetween p="10px" sx={{ justifyContent: "left" }}>
            <Box pl={"10px"}>
              <CustomSwitch
                isNonMobileScreens={isNonMobileScreens}
                restakingSwitch={buySellSwitch === "buy" ? true : false}
                handleChangeRestakingSwitch={handleBuySellSwitch}
              />
            </Box>
            <Typography fontSize={isNonMobileScreens ? "14px" : "8px"} color={buySellSwitch === "buy" ? "green" : "red"}>{buySellSwitch}</Typography>
          </FlexBetween >

        </FlexBetween>

        <FlexBetween p={isNonMobileScreens ? "10px" : "10px 0"} display="felx" >
          {totalProfit.length !== 0 &&
            <StakeButton
              text={"Save data in blockchain"}
              stakingLoading={blockchainSaveLoading}
              isNonMobileScreens={isNonMobileScreens}
              backgroundColor={textFIeld}
              hooverBackgroundColor={hooverBackground}
              textColor={colorBalance}
              onClick={handleSaveInBlockchainButton}
            />
          }

          <StakeButton
            text={"Save"}
            stakingLoading={false}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleSaveLocalButton}
          />


        </FlexBetween>

        {totalProfit.length !== 0 &&
          <>

            <Box p={"10px"} display={"flex"} justifyContent={"center"} >
              <Box width={"100%"} >
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    variant="contained"
                    onClick={handleToggleHide}
                    sx={{
                      backgroundColor: textFIeld,
                      color: colorMain,
                      borderRadius: "2rem",
                      "&:hover": {
                        backgroundColor: background,
                        cursor: "pointer",
                      },
                    }}
                  >{hide ? "Show all transactions" : "Hide all transactions"}</Button>
                </Box>
                {!hide && (
                  <List>
                    {buyList.length === 0 && <Typography sx={{ paddingTop: "15px", color: colorMain }}>No purchases to show</Typography>}
                    {buyList.map((buyOrder, index) => (
                      <ListItem key={buyOrder.id}>
                        {/* <Box display={"grid"} gridTemplateColumns={"repeat(3, 1fr)"} sx={{ gridGap: "30px" }} alignItems={"center"}> */}
                        <FlexBetween sx={{ gridGap: "30px" }} alignItems={"center"}>
                          <ListItemText
                            primary={
                              <>
                                {buyOrder.azeroAmount}
                                <AzeroImg margin={"-10px 0.5px 0 3px"} width={isNonSmallMobileScreens ? "9px" : "8px"} isNonSmallMobileScreens={isNonSmallMobileScreens} />
                              </>}
                            primaryTypographyProps={{ color: colorMain }}
                            secondary={"$" + buyOrder.price}
                            secondaryTypographyProps={{ color: colorSecondary }}
                          />
                          {/* <Typography>{buyOrder.azeroAmount} <AzeroImg width={"12px"} /> &nbsp;&nbsp;&nbsp;</Typography>
                          <Typography fontSize={"10px"}>at $ {buyOrder.price} </Typography> */}
                          {/* {buyOrder.txTypeIsBuy === true ?
                            <Typography
                              fontSize={"14px"}
                              fontWeight={"900"}
                              color={azeroPrice < Number(buyOrder.price) ? "red" : "green"}
                            >
                              {"$ " + (Number(buyOrder.azeroAmount) * (azeroPrice - Number(buyOrder.price))).toFixed(2).replace(/[.,]00$/, "")}
                            </Typography>
                            :
                            <Typography
                              fontSize={"14px"}
                              fontWeight={"900"}
                              color={azeroPrice > Number(buyOrder.price) ? "red" : "green"}
                            >
                              {"$ " + (Number(buyOrder.azeroAmount) * (Number(buyOrder.price) - azeroPrice)).toFixed(2).replace(/[.,]00$/, "")}
                            </Typography>
                          } */}

                          {/* <div style={{ flex: "1" }}></div> */}
                          <ListItemSecondaryAction>
                            <FlexBetween>
                              <Typography
                                fontSize={"14px"}
                                fontWeight={"900"}
                                color={buyOrder.txTypeIsBuy ? "green" : "red"}
                              >
                                {buyOrder.txTypeIsBuy ? "Buy" : "Sell"}
                              </Typography>
                              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTodo(buyOrder.id)}>
                                <DeleteIcon style={{ color: colorMain }} />
                              </IconButton>
                            </FlexBetween>
                          </ListItemSecondaryAction>
                        </FlexBetween>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </Box>

          </>
        }


      </WidgetWrapper >
    </Box >
  );
};
