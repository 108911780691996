export const toastMessages = {
  NO_EXTENSION: "Your browser does NOT HAVE any of the required wallet extensions.",
  NO_WALLET: "You haven't connected your wallet.",
  NO_ACCOUNT: "You must let this website to access your wallet in your wallet's settings.",

  ERR_FETCHING_DATA: "Error during fetching data.",
  ERR_API_CONN: "Error occurred with API connection.",
  ERR_CONTRACT_DATA: "Error occurred when setting up a contract.",

  INVALID_ADDRESS: "Invalid contract address. Please try again.",
  NO_TOKEN_SELECTED: "You have not selected token yet!",
  CUSTOM: "An error occurred: ",
};

export const supportWallets = [
  {
    name: "SubWallet",
    title: "subwallet",
    extensionName: "subwallet-js",
  },
  // {
  //   name: "Nova",
  //   title: "nova",
  //   extensionName: "nova",
  // },
  {
    name: "Polkadot JS",
    title: "polkadot",
    extensionName: "polkadot-js",
  },
  {
    name: "Talisman",
    title: "talisman",
    extensionName: "talisman",
  },

];
