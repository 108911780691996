import React, { useState } from "react";
import { Box, useMediaQuery, Typography, useTheme } from "@mui/material";

import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { DECIMALS } from "config/constants"
import { toast } from "react-hot-toast";
import TypingEffect from "components/TypingEffect";
import CustomSwitch from "components/CustomSwitch";
import { StakeButton, StakeTextField } from "components/staking";

export const FirstStakeNominator = ({ api, account, isConnected, balance, stakedBalanceNominator, injector, calculateAllBalances }) => {
  const isNonMobileScreens = useMediaQuery("(min-width:600px)");
  const widthAmountBox = isNonMobileScreens ? "12rem" : "5rem"
  const fontSizeInputBox = isNonMobileScreens ? "1rem" : "0.5rem"

  const [stakingLoadingNominator, setStakingLoadingNominator] = useState(false);
  const [stakingAmountNominator, setStakingAmountNominator] = useState("");
  const [restakingSwitch, setRestakingSwitch] = useState(true);


  const { palette } = useTheme();
  const hooverBackground = palette.connectButton.hooverBackground;
  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;
  const textFIeld = palette.widgets.textFIeld;


  const handleChangeRestakingSwitch = () => {
    setRestakingSwitch(() => !restakingSwitch)
  }

  const handleStakeButtonNominator = async () => {

    if (!isConnected) {
      toast.error("Connect a wallet first!");
      return;
    }

    if (stakedBalanceNominator !== 0) {
      toast.error(`This Account (${account.address.slice(0, 4) + "..." + account.address.slice(44, 48)}) is actually a nominator. You can increase the staking amount in the section above.`);
      return;
    }

    if (stakingAmountNominator < 2000) {
      toast.error("Minimum staking amount is 2000 AZERO");
      return;
    }

    setStakingLoadingNominator(true)

    if (!isConnected) return;

    try {
      let restaking;
      if (restakingSwitch === true) {
        restaking = "Staked"
      } else {
        restaking = "Stash"
      }

      // bond(controllerr, value, payee) payee es si queremos reestakear y si queremos poner otra cuenta para los rewards (Stash === rewards to account, Staked === rewards go to stake)
      const txs = [
        api.tx.staking.bond(account.address, BigInt(Number(stakingAmountNominator) * DECIMALS), restaking),
        api.tx.staking.nominate([process.env.REACT_APP_VALIDATOR_ID]), // SBG validator
      ];
      const txHashes = await api.tx.utility.batch(txs).signAndSend(account.address, { signer: injector.signer });
      console.log("txHashes: ", txHashes.toString())

      await new Promise(r => setTimeout(r, 3000));
      toast.success(`${stakingAmountNominator} AZEROS staked succesfully`)
      calculateAllBalances()

    } catch (err) {
      console.log("Error when bonding: ", err)
      setStakingLoadingNominator(false)
    }
    setStakingLoadingNominator(false)
  }


  return (
    <Box aria-label="FirstStakeNominator">
      <WidgetWrapper backgroundColor={background} borderColor={border}>

        {/* STAKE */}
        <FlexBetween color={colorTittle} fontSize={isNonMobileScreens ? "1.5rem" : "12px"} sx={{ justifyContent: "left", fontWeight: "900" }}>
          <TypingEffect text="First stake as nominator" typingSpeed={200} />
        </FlexBetween>
        <FlexBetween pb="10px" fontSize={isNonMobileScreens ? "1rem" : "10px"} color={colorTittle} sx={{ justifyContent: "left" }}>
          Min &nbsp;<span style={{ fontWeight: "900" }}>2000</span> &nbsp;Azeros
        </FlexBetween>

        <FlexBetween p={isNonMobileScreens ? "10px" : ""} sx={{ justifyContent: "left" }}>
          <Typography color={colorBalance} fontSize={isNonMobileScreens ? "14px" : "7.5px"} >Re-staking rewards</Typography>

          <Box pl={"10px"}>
            <CustomSwitch
              isNonMobileScreens={isNonMobileScreens}
              restakingSwitch={restakingSwitch}
              handleChangeRestakingSwitch={handleChangeRestakingSwitch}
            />
          </Box>

        </FlexBetween>

        <FlexBetween p={isNonMobileScreens ? "10px" : ""} gap="10px" sx={{ display: "flex", alignItems: "end" }}>

          <StakeTextField
            text={"Amount to stake:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={stakingAmountNominator}
            setStakingAmountPool={setStakingAmountNominator}
            minAmount={"2000"}
          />

          <StakeButton
            text={"Stake"}
            stakingLoading={stakingLoadingNominator}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleStakeButtonNominator}
          />

        </FlexBetween>

      </WidgetWrapper>
    </Box>
  );
};
