import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import PropTypes from 'prop-types'


export const StakeTextField = ({ text, isNonMobileScreens, descriptionTextColor, inputTextColor, inputBackgroundColor, stakingAmountPool, setStakingAmountPool, minAmount = "" }) => {

  const handleStakeInputChangePool = (event) => {
    setStakingAmountPool(event.target.value);
  };

  return (
    <Box>
      <Typography fontSize={isNonMobileScreens ? "10px" : "6px"} color={descriptionTextColor}>
        {text}
      </Typography>

      <TextField
        id="outlined-number"
        aria-label="inputTextField"
        type="number"
        value={stakingAmountPool}
        onChange={handleStakeInputChangePool}
        InputProps={{ inputProps: { min: Number(minAmount) } }}
        placeholder={minAmount.toString()}
        size={isNonMobileScreens ? "large" : "small"}
        sx={{
          width: "100%",
          color: inputTextColor,
          borderRadius: "2rem",
          backgroundColor: inputBackgroundColor,
          '& input': {
            color: inputTextColor,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
              borderWidth: 0
            },
            "&:hover fieldset": {
              borderColor: "transparent"
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent"
            }
          }
        }}
      />
    </Box>
  )
}

StakeTextField.propTypes = {
  text: PropTypes.string.isRequired,
  isNonMobileScreens: PropTypes.bool.isRequired,
  descriptionTextColor: PropTypes.string.isRequired,
  inputTextColor: PropTypes.string.isRequired,
  inputBackgroundColor: PropTypes.string.isRequired,
  setStakingAmountPool: PropTypes.func.isRequired,
}

