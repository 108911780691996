import React, { useState } from "react";
import { Box, useMediaQuery, Typography, TextField, Button, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { DECIMALS } from "config/constants"
import AzeroImg from "components/AzeroImg";
import TypingEffect from "components/TypingEffect";
import { StakeButton, StakeTextField } from "components/staking";
import { toast } from "react-hot-toast";


export const StakePool = ({
  api,
  account,
  isConnected,
  stakedBalancePool,
  poolId,
  injector,
  transferable,
  pendingRewardsPool,
  isWithdrawUnbonded,
  amountToWithdraw,
  calculateAllBalances
}) => {
  const isNonMobileScreens = useMediaQuery("(min-width:600px)");

  const [stakingLoadingPool, setStakingLoadingPool] = useState(false);
  const [stakingAmountPool, setStakingAmountPool] = useState("");
  const [ustakingLoadingPool, setUnstakingLoadingPool] = useState(false);
  const [unstakingAmountPool, setUnstakingAmountPool] = useState("");
  const [withdrawLoadingPool, setWithdrawLoadingPool] = useState(false);

  const [claimLoadingPool, setClaimLoadingPool] = useState(false);

  const { palette } = useTheme();
  const hooverBackground = palette.connectButton.hooverBackground;
  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;
  const textFIeld = palette.widgets.textFIeld;


  const handleStakeButtonPool = async () => {

    if (transferable < Number(stakingAmountPool)) {
      toast.error("The amount you want to stake is greater than your available balance.");
      return;
    }

    if (transferable - Number(stakingAmountPool) < 0.01) {
      toast.error("Your balance is not enough to pay the transaction signing fees.");
      return;
    }

    setStakingLoadingPool(true)

    if (stakingAmountPool === "0" || stakingAmountPool === "") {
      toast.error("It is not possible stake 0 AZERO");
      setStakingLoadingPool(false)
      return
    }

    if (Number(stakingAmountPool) < 0) {
      toast.error("It is not possible stake a negative number");
      setStakingLoadingPool(false)
      return
    }

    try {
      // Bond more funds if account is a nominator
      const tx = await api.tx.nominationPools.bondExtra({ FreeBalance: BigInt(Number(stakingAmountPool) * DECIMALS) }).signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      await calculateAllBalances()
      setStakingAmountPool("")
    } catch (err) {
      console.log("Error when bonding: ", err)
      setStakingLoadingPool(false)
      setStakingAmountPool("")
    }
    setStakingLoadingPool(false)
  }

  const handleUnstakeButtonPool = async () => {

    if (transferable < 0.01) {
      toast.error("Your balance is not enough to pay the transaction signing fees.");
      return;
    }

    setUnstakingLoadingPool(true)

    if (unstakingAmountPool === "0" || unstakingAmountPool === "") {
      toast.error("It is not possible unstake 0 AZERO");
      setUnstakingLoadingPool(false)
      return
    }

    if (Number(unstakingAmountPool) < 0) {
      toast.error("It is not possible unstake a negative number");
      setUnstakingLoadingPool(false)
      return
    }

    try {
      // Bond more funds if account is a nominator
      const tx = await api.tx.nominationPools.unbond(account.address, BigInt(Number(unstakingAmountPool) * DECIMALS)).signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      await calculateAllBalances()
      setUnstakingAmountPool("")
    } catch (err) {
      console.log("Error when bonding: ", err)
      setUnstakingLoadingPool(false)
      setUnstakingAmountPool("")
    }
    setUnstakingLoadingPool(false)
  }

  const handleWithdrawButtonPool = async () => {

    if (transferable < 0.01) {
      toast.error("Your balance is not enough to pay the transaction signing fees.");
      return;
    }

    setWithdrawLoadingPool(true)
    try {
      // Unbond whitdrawable funds
      const tx = await api.tx.nominationPools.withdrawUnbonded(account.address, 0).signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      await calculateAllBalances()

    } catch (err) {
      console.log("Error when bonding: ", err)
      setWithdrawLoadingPool(false)
    }
    setWithdrawLoadingPool(false)
  }

  const handleClaimButtonPool = async () => {

    if (Number(pendingRewardsPool) === 0) {
      toast.error("You have nothing to claim.");
      return;
    }

    if (transferable < 0.01) {
      toast.error("Your balance is not enough to pay the transaction signing fees.");
      return;
    }

    setClaimLoadingPool(true)

    if (!isConnected) return;

    try {
      // Bond more funds if account is a nominator
      const tx = await api.tx.nominationPools.claimPayout().signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      await calculateAllBalances()
      // setPendingRewardsPool(0)
    } catch (err) {
      console.log("Error when bonding: ", err)
      setClaimLoadingPool(false)
    }
    setClaimLoadingPool(false)
  }


  return (
    <Box aria-label="StakePool">
      <WidgetWrapper backgroundColor={background} borderColor={border}>
        {/* STAKE */}
        <FlexBetween pb="10px" color={colorTittle} fontSize={isNonMobileScreens ? "24px" : "12px"} sx={{ fontWeight: "bold", justifyContent: "left" }}>
          <TypingEffect text="POOL" typingSpeed={200} />
        </FlexBetween>

        <FlexBetween p={isNonMobileScreens ? "10px" : ""} gap="10px" sx={{ display: "flex", alignItems: "end" }}>

          <StakeTextField
            text={"Amount to stake:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={stakingAmountPool}
            setStakingAmountPool={setStakingAmountPool}
          />

          <StakeButton
            text={"Stake"}
            stakingLoading={stakingLoadingPool}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleStakeButtonPool}
          />

        </FlexBetween>


        {/* UNSTAKE  */}
        <FlexBetween p={isNonMobileScreens ? "10px" : ""} gap="10px" sx={{ display: "flex", alignItems: "end" }}>

          <StakeTextField
            text={"Amount to unstake:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={unstakingAmountPool}
            setStakingAmountPool={setUnstakingAmountPool}
          />

          <StakeButton
            text={"Unstake"}
            stakingLoading={ustakingLoadingPool}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleUnstakeButtonPool}
          />

        </FlexBetween>

        {/* CLAIM  */}
        <FlexBetween p={isNonMobileScreens ? "10px" : "10px 0"}>
          <FlexBetween sx={{ justifyContent: "left" }}>
            <Typography color={colorBalance} fontSize={isNonMobileScreens ? "10px" : "7px"}>
              Claimable amount:  <span style={{ fontWeight: "900", color: colorSecondary }}>{pendingRewardsPool}</span>
              <AzeroImg width={isNonMobileScreens ? "11px" : "8px"} isNonSmallMobileScreens={isNonMobileScreens} />
            </Typography>
          </FlexBetween>

          <StakeButton
            text={"Claim"}
            stakingLoading={claimLoadingPool}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleClaimButtonPool}
          />

        </FlexBetween>

        {/* WITHDRAW UNBONDED  */}
        {isWithdrawUnbonded &&
          <FlexBetween p={isNonMobileScreens ? "10px" : ""}>
            <Typography color={colorBalance} fontSize={isNonMobileScreens ? "10px" : "7px"}>
              Withdraw available: &nbsp; <span style={{ fontWeight: "900", color: colorSecondary }}>{amountToWithdraw}</span>
              <AzeroImg width={isNonMobileScreens ? "11px" : "8px"} isNonSmallMobileScreens={isNonMobileScreens} />
            </Typography>

            <StakeButton
              text={"Withdraw"}
              stakingLoading={withdrawLoadingPool}
              isNonMobileScreens={isNonMobileScreens}
              backgroundColor={textFIeld}
              hooverBackgroundColor={hooverBackground}
              textColor={colorBalance}
              onClick={handleWithdrawButtonPool}
            />

          </FlexBetween>
        }

      </WidgetWrapper>
    </Box >
  );
};
