import React, { useContext } from "react";
import { Typography, useTheme, Button, Box, useMediaQuery } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import StakingPieChart from "./StakingPieChart";
import TypingEffect from "components/TypingEffect";
import { PolkadotContext } from "providers/PolkadotProvider";


const Charts = ({
  transferable,
  stakedBalance,
  unboundingAmountPool,
  pendingRewardsPool
}) => {
  const isNonSmallMobileScreens = useMediaQuery("(min-width:600px)");
  const { themeMode } = useContext(PolkadotContext);

  const { palette } = useTheme();
  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;

  const moneyData = [
    { name: 'Staked', value: Number(stakedBalance) },
    { name: 'Available', value: Number(transferable) },
    { name: 'Unstaking', value: Number(unboundingAmountPool) }]

  return (
    <WidgetWrapper backgroundColor={background} borderColor={border}>
      <FlexBetween >
        <Box>
          <Typography color={colorTittle} variant="h3" fontWeight="900" fontSize={!isNonSmallMobileScreens && "12px"}>
            <TypingEffect text="Statistics" typingSpeed={200} />
          </Typography>
        </Box>
      </FlexBetween>
      {Number(transferable) <= 0 && Number(stakedBalance) <= 0 && Number(unboundingAmountPool) <= 0 && Number(pendingRewardsPool) <= 0 ? (
        <Box p={"20px 0"}>
          <Typography color={colorMain}>No info to show</Typography>
        </Box>
      ) : (
        <Box  >
          <StakingPieChart data={moneyData} themeMode={themeMode} />
          {/* <DonutChart spent={Number(transferable)} available={Number(stakedBalance)} saves={Number(unboundingAmountPool)} /> */}
        </Box>
      )}
    </WidgetWrapper>
  );
};

export default Charts;


