import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Search,
  Message,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Menu,
  Close,
  Radar,
  MenuOutlined,
} from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import FlexBetween from "components/FlexBetween";
import { PolkadotContext } from "providers/PolkadotProvider";
import WalletButton from "components/wallet/WalletButton";
import { borderRadius } from "@mui/system";


const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const isMenuIconHidden = useMediaQuery("(min-width: 1160px)");
  const isNonMobileScreens = useMediaQuery("(min-width: 680px)");
  const isNonSmallMobileScreens = useMediaQuery("(min-width:400px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  // const dark = theme.palette.primary.light;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;

  const background = theme.palette.navbar.background;
  const menuIcon = theme.palette.navbar.menuIcon;
  const connectButton = theme.palette.navbar.connectButton;
  const textColor = theme.palette.navbar.textColor;


  const { themeMode, setThemeMode } = useContext(PolkadotContext);
  const onClickDarkMode = () => {
    () => (themeMode === "dark" ? setThemeMode("dark") : setThemeMode("light"))
    if (themeMode === "dark") { setThemeMode("light") }
    else { setThemeMode("dark") }
  };

  const handleOnClickGoToSbg = async (event) => {
    if (event.button === 1) { // listen to mouse scroll click
      window.open("https://sbgworld.io", '_blank').focus();
    } else {
      window.open("https://sbgworld.io", "_blank").focus();
    }
  }


  return (
    <FlexBetween
      // width={"calc(100vh - 100px )"}
      width={"100%"}
      padding={isNonSmallMobileScreens ? "1.5rem 6% 1.5rem 6%" : "0.75rem 6% 0.75rem 6%"}
      backgroundColor={background}
    >
      <FlexBetween gap="0.25rem">

        <Typography
          fontSize={isNonMobileScreens ? "clamp(1rem, 2rem, 2.25rem)" : isNonSmallMobileScreens ? "1.25rem" : "0.75rem"} // clamp: si la pantalla es muy pequna como min baja a 1 y si es muy gande como mucho sube a 2.25, por defecto en 2
          color={textColor}
          fontWeight="bold"
          onClick={handleOnClickGoToSbg}
          onAuxClick={handleOnClickGoToSbg}
          sx={{
            "&:hover": {
              color: menuIcon,
              cursor: "pointer",
            },
          }}
        > SBG
        </Typography>

        <Typography
          pl={isNonMobileScreens ? "5px" : isNonSmallMobileScreens ? "2px" : ""}
          // fontSize="clamp(1rem, 2rem, 2.25rem)" // clamp: si la pantalla es muy pequna como min baja a 1 y si es muy gande como mucho sube a 2.25, por defecto en 2
          // fontSize={isNonMobileScreens ? "clamp(1rem, 2rem, 2.25rem)" : "1.25rem"}
          fontSize={isNonMobileScreens ? "clamp(1rem, 2rem, 2.25rem)" : isNonSmallMobileScreens ? "1.25rem" : "0.75rem"}
          // fontSize={ isNonSmallMobileScreens? "1.25rem": "0.5rem" }
          color={textColor}
        >staking</Typography>


        {/* <Typography></Typography> */}

      </FlexBetween>



      {/* DESKTOP NAV */}
      {isMenuIconHidden ? (
        <FlexBetween gap="2rem">

          <Typography
            fontFamily="Poppins"
            fontSize="16px"
            // fontWeight="bold"
            color={textColor}
            onClick={handleOnClickGoToSbg}
            onAuxClick={handleOnClickGoToSbg}
            sx={{
              "&:hover": {
                color: menuIcon,
                cursor: "pointer",
              },
            }}
          >
            SERVICIOS
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize="16px"
            color={textColor}
            // fontWeight="bold"
            onClick={handleOnClickGoToSbg}
            onAuxClick={handleOnClickGoToSbg}
            sx={{
              "&:hover": {
                color: menuIcon,
                cursor: "pointer",
              },
            }}
          >
            PROYECTOS
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize="16px"
            color={textColor}
            // fontWeight="bold"
            onClick={handleOnClickGoToSbg}
            onAuxClick={handleOnClickGoToSbg}
            sx={{
              "&:hover": {
                color: menuIcon,
                cursor: "pointer",
              },
            }}
          >
            EQUIPO
          </Typography>
          <Typography
            fontFamily="Poppins"
            fontSize="16px"
            color={textColor}
            // fontWeight="bold"
            onClick={handleOnClickGoToSbg}
            onAuxClick={handleOnClickGoToSbg}
            sx={{
              "&:hover": {
                color: menuIcon,
                cursor: "pointer",
              },
            }}
          >
            CONTACTO
          </Typography>

          <IconButton
            onClick={onClickDarkMode}
          >
            {theme.palette.mode === "dark" ? (
              <DarkMode sx={{ fontSize: "25px" }} />
            ) : (
              <LightMode sx={{ color: connectButton, fontSize: "25px" }} />
            )}
          </IconButton>

          <WalletButton />

        </FlexBetween>

      ) : (
        <FlexBetween gap="2rem">
          {!isMenuIconHidden && isNonMobileScreens && <WalletButton />}
          <MenuOutlined
            color="primary"
            fontSize={isNonSmallMobileScreens ? "large" : "small"}
            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)} // para que se abra el menu al pulsarlo en un futuro
          >
            <Menu />
          </MenuOutlined>
        </FlexBetween>
      )}


      {/* MOBILE NAV */}
      {!isMenuIconHidden && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth={isNonSmallMobileScreens ? "300px" : "200px"}
          backgroundColor={background}
        >

          {/* CLOSE ICON */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>



          {/* MENU ITEMS */}
          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >

            {!isNonMobileScreens &&
              <Box width={"130px"} display={"felx"} justifyContent={"center"}>
                <WalletButton setIsMobileMenuToggled={setIsMobileMenuToggled} />
              </Box>
            }

            <IconButton
              onClick={onClickDarkMode}
            >
              {theme.palette.mode === "dark" ? (
                <DarkMode sx={{ fontSize: "25px" }} />
              ) : (
                <LightMode sx={{ color: connectButton, fontSize: "25px" }} />
              )}
            </IconButton>

            <Typography
              // fontWeight="bold"
              fontFamily="Poppins"
              color="neutral"
              onClick={handleOnClickGoToSbg}
              onAuxClick={handleOnClickGoToSbg}
              sx={{
                "&:hover": {
                  color: menuIcon,
                  cursor: "pointer",
                },
              }}
            >
              Servicios
            </Typography>
            <Typography
              // fontWeight="bold"
              fontFamily="Poppins"
              color="neutral"
              onClick={handleOnClickGoToSbg}
              onAuxClick={handleOnClickGoToSbg}
              sx={{
                "&:hover": {
                  color: menuIcon,
                  cursor: "pointer",
                },
              }}
            >
              Proyecto
            </Typography>
            <Typography
              // fontWeight="bold"
              fontFamily="Poppins"
              color="neutral"
              onClick={handleOnClickGoToSbg}
              onAuxClick={handleOnClickGoToSbg}
              sx={{
                "&:hover": {
                  color: menuIcon,
                  cursor: "pointer",
                },
              }}
            >
              Equipo
            </Typography>
            <Typography
              // fontWeight="bold"
              fontFamily="Poppins"
              color="neutral"
              onClick={handleOnClickGoToSbg}
              onAuxClick={handleOnClickGoToSbg}
              sx={{
                "&:hover": {
                  color: menuIcon,
                  cursor: "pointer",
                },
              }}
            >
              Contacto
            </Typography>

          </FlexBetween>
        </Box>
      )}

    </FlexBetween >
  );
};

export default Navbar;
