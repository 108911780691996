import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import { ApiPromise, WsProvider } from "@polkadot/api";
// import { keyring } from "@polkadot/ui-keyring";
import { web3Accounts, web3Enable, web3FromAddress } from '@polkadot/extension-dapp';

import { DECIMALS } from 'config/constants';
import { supportWallets } from 'config/wallet';
import axios from 'axios';
// import { POOL_A_ID, POOL_B_ID, POOL_C_ID, POOL_A_NAME, POOL_B_NAME, POOL_C_NAME } from 'config/config';

export const PolkadotContext = createContext();

export const PolkadotProvider = ({ children }) => {
  const localCurrentAccountTheme = window?.localStorage?.getItem(
    "themeMode"
  );
  if (!localCurrentAccountTheme) localStorage.setItem("themeMode", JSON.stringify("dark"));
  const [themeMode, setThemeMode] = useState(JSON.parse(localCurrentAccountTheme) || "dark");
  const [isAndroidOrIos, setIsAndroidOrIos] = useState(false);

  const [isConnectButtonMarked, setIsConnectButtonMarked] = useState(null);
  const [isMainnet, setIsMainnet] = useState(JSON.parse(process.env.REACT_APP_MAINNET_BY_DEFAULT.toLowerCase()));

  const [api, setApi] = useState(null);
  const [allAccounts, setAllAccounts] = useState([]);

  const localCurrentAccount = window?.localStorage?.getItem(
    "localCurrentAccount"
  );
  const [currentAccount, setCurrentAccount] = useState(JSON.parse(localCurrentAccount) || null);

  const [selectedWalletExt, setSelectedWalletExt] = useState(null);
  const [extensionsNotInstalled, setExtensionsNotInstalled] = useState([]);
  const [injector, setInjector] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const [balance, setBalance] = useState(0); // ojo que en el balance no se tienen en cuenta los datos de la pool (stakedBalancePool, unboundingAmountPool, pendingRewardsPool)
  const [azeroPrice, setAzeroPrice] = useState(0);
  const [azeroPriceChange, setAzeroPriceChange] = useState("");

  // NOMINATOR
  const [stakedBalanceNominator, setStakedBalanceNominator] = useState(0);
  const [validatorAsociatedAccounts, setValidatorAsociatedAccounts] = useState([]);
  const [payeeNominator, setPayeeNominator] = useState("");
  const [unboundingAmountNominator, setUnboundingAmountNominator] = useState([]);
  const [unboundingErasNominator, setUnboundingErasNominator] = useState([]); // era en la cual se puede destakear
  const [isWithdrawUnbondedNominator, setIsWithdrawUnbondedNominator] = useState(false);
  const [amountToWithdrawNominator, setAmountToWithdrawNominator] = useState(0);

  //POOL
  const [stakedBalancePool, setStakedBalancePool] = useState(0);
  const [unboundingAmountPool, setUnboundingAmountPool] = useState([]);
  const [unboundingErasPool, setUnboundingErasPool] = useState([]); // era en la cual se puede destakear
  const [pendingRewardsPool, setPendingRewardsPool] = useState(0);
  const [poolId, setPoolId] = useState(0);
  // const [poolName, setPoolName] = useState("");
  const [isWithdrawUnbondedPool, setIsWithdrawUnbondedPool] = useState(false);
  const [amountToWithdrawPool, setAmountToWithdrawPool] = useState(0);


  // const getPoolNameFromId = (poolId) => {
  //   if (poolId === POOL_A_ID) return POOL_A_NAME
  //   if (poolId === POOL_B_ID) return POOL_B_NAME
  //   if (poolId === POOL_C_ID) return POOL_C_NAME
  //   else return "Pool not exists"
  // }

  const getAzeroPrice = async () => {
    const response = await axios.get('https://alephzero.api.subscan.io/api/scan/token', {
      headers: {
        'X-API-Key': process.env.REACT_APP_SUBSCAN_API_KEY,
      },
    });
    if (response.status === 200) {
      setAzeroPrice(Number(response.data.data.detail.AZERO.price))
      setAzeroPriceChange(response.data.data.detail.AZERO.price_change)
    }
  }

  function getDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setIsAndroidOrIos(true)
      return true;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsAndroidOrIos(true)
      return true;
    }
    return false;
  }

  const getNotInstalledExtensions = async (installedExtensions) => {
    // si el dispositivo es un Android o IOS no se muestran las wallets no instaladas opacas
    const isAndroidOrIosDevice = getDeviceType()
    if (isAndroidOrIosDevice) {
      return
    }

    if (installedExtensions !== undefined) return

    let walletNotInstalled = supportWallets.map((item) => item.extensionName)
    supportWallets.map((itemSupported) => {
      installedExtensions.map((itemInstalledExtensions) => {
        if (itemSupported.extensionName === itemInstalledExtensions.name) {
          walletNotInstalled = walletNotInstalled.filter((value) => { return value !== itemSupported.extensionName })
        }

      });
    });

    setExtensionsNotInstalled(walletNotInstalled)
  }


  const getAccounts = async () => {
    const extensions = await web3Enable(process.env.REACT_APP_NAME);
    await getNotInstalledExtensions(extensions);
    const allAccounts = await web3Accounts();

    let allAccountsAux = []
    if (allAccounts !== undefined) {
      allAccounts.map(function (element, i) {
        allAccountsAux.push({
          address: element.address,
          name: element.meta.name,
          balance: element.balance,
          source: element.meta.source,
        })
      })
      setAllAccounts(allAccountsAux)
    }
  }

  const connectToPolkadotAccoount = async (currAccount) => {
    try {
      setCurrentAccount(null);

      const extensions = await web3Enable(process.env.REACT_APP_NAME);
      if (extensions === []) {
        setIsConnected(false);
        return
      }

      if (currAccount !== null) {
        setSelectedWalletExt(currAccount.source)
      }

      const allAccounts = await web3Accounts();

      let allAccountsAux = []
      if (allAccounts !== undefined) {
        allAccounts.map(function (element, i) {
          allAccountsAux.push({
            address: element.address,
            name: element.meta.name,
            balance: element.balance,
            source: element.meta.source,
          })
        })
        setAllAccounts(allAccountsAux)
      }

      getNotInstalledExtensions(extensions);

      if (currAccount === null) {
        setIsConnected(false);
        return
      }

      // si la account guardadda en localStorage no esta conectada a la web en la wallet que no intente conectar
      let isAccountAlowedInWeb = false;
      allAccountsAux.map(function (element, i) {
        if (currAccount.address === element.address) {
          isAccountAlowedInWeb = true
        }
      })
      if (!isAccountAlowedInWeb) {
        setIsConnected(false);
        setCurrentAccount(null);
        return
      }


      let currAccountInfo;
      allAccountsAux.map(function (element, i) {
        if (element.address === currAccount.address) {
          currAccountInfo = element
        }
      })

      if (currAccountInfo === null) {
        setIsConnected(false);
        return
      }

      let provider;
      if (isMainnet) {
        provider = new WsProvider(process.env.REACT_APP_WSPROVIDER_MAINNET);
      }
      else {
        provider = new WsProvider(process.env.REACT_APP_WSPROVIDER_DEVNET);
      }

      const api = await ApiPromise.create({ provider, signer: currAccountInfo });
      await api.isReady;
      setInjector(await web3FromAddress(currAccountInfo.address))

      setCurrentAccount(currAccount);
      setApi(api);
      localStorage.setItem("localCurrentAccount", JSON.stringify(currAccountInfo));

      setIsConnected(true);
    } catch (err) {
      console.log("Wallet not connected: " + err)
    }
  };

  const connectToPolkadotAccoountFirstTime = async () => {
    try {

      const extensions = await web3Enable(process.env.REACT_APP_NAME);
      if (extensions === []) {
        setIsConnected(false);
        return
      }
      getNotInstalledExtensions(extensions);

      const allAccounts = await web3Accounts();

      let allAccountsAux = []
      allAccounts.map(function (element, i) {
        allAccountsAux.push({
          address: element.address,
          name: element.meta.name,
          balance: element.balance,
          source: element.meta.source,
        })
      })
      setAllAccounts(allAccountsAux)

      let provider;
      if (isMainnet) {
        provider = new WsProvider(process.env.REACT_APP_WSPROVIDER_MAINNET);
      }
      else {
        provider = new WsProvider(process.env.REACT_APP_WSPROVIDER_DEVNET);
      }

      const api = await ApiPromise.create({ provider, signer: allAccounts[0] });
      await api.isReady;
      setInjector(await web3FromAddress(allAccounts[0].address))

      setCurrentAccount(allAccountsAux[0]);
      setApi(api);
      localStorage.setItem("localCurrentAccount", JSON.stringify(allAccountsAux[0]));

      setIsConnected(true);
    } catch (err) {
      console.log("Wallet not connected: " + err)
    }
  };


  const disconnectFromPolkadot = () => {
    setApi(null);
    setCurrentAccount(null);
    setIsConnected(false);
    localStorage.removeItem("localCurrentAccount");
  };

  const checkStakingInfoNominator = async () => {
    try {
      // Get the balance of staking in a nominator
      const stakingBalanceRaw = await api.query.balances.locks(currentAccount.address);
      const stakingBalanceParsed = JSON.parse(stakingBalanceRaw)

      if (stakingBalanceParsed.length !== 0) {
        // setStakedBalanceNominator((parseInt(stakingBalanceParsed[0].amount) / DECIMALS).toFixed(2).replace(/[.,]00$/, ""))
        const payee = await api.query.staking.payee(currentAccount.address);
        setPayeeNominator(payee.toString())
      }

      //  Get the validator asociated to this nominator
      const stakingNominatorsRaw = await api.query.staking.nominators(currentAccount.address)
      const stakingNominators = JSON.parse(JSON.stringify(stakingNominatorsRaw));

      if (stakingNominators !== null) {
        if (Object.keys(stakingNominators.targets).length !== 0) {
          setValidatorAsociatedAccounts(stakingNominators.targets)
        }
      }

      const stakingLedgerInfoRaw = await api.query.staking.ledger(currentAccount.address);
      if (stakingLedgerInfoRaw.toString() !== "") {
        const stakingLedgerInfo = JSON.parse(stakingLedgerInfoRaw)
        setStakedBalanceNominator(Number((parseInt(stakingLedgerInfo.active) / DECIMALS).toFixed(2).replace(/[.,]00$/, "")))

        if (stakingLedgerInfo.unlocking.length !== 0) {
          const currentEra = await api.query.staking.currentEra()
          let eras = []
          let amount = []
          let amountWhithdrawable = 0;
          for (let i = 0; i < stakingLedgerInfo.unlocking.length; i++) {
            const erasTotal = stakingLedgerInfo.unlocking[i]["era"]
            const amountAux = (Number(stakingLedgerInfo.unlocking[i]["value"]) / DECIMALS).toFixed(2).replace(/[.,]00$/, "")
            // si las eras para desstake son 0 o menos ya se puede cobrara y no se muestran
            if (Number(erasTotal) - Number(currentEra) > 0) {
              eras.push(Number(erasTotal) - Number(currentEra))
              amount.push(amountAux)
            } else { // si las eras para desstake son 0 o menos ya se puede cobrara y se suma a withdraw amount
              amountWhithdrawable += Number(amountAux)
            }
          }

          setUnboundingErasNominator(eras)
          setUnboundingAmountNominator(amount)
          setAmountToWithdrawNominator(amountWhithdrawable)
          setIsWithdrawUnbondedNominator(() => { return amountWhithdrawable !== 0 })
        }
      }
    } catch (err) {
      console.log("Error when getting the account balance")
      console.log("error: ", err)
    }
  }

  const checkAccountBalance = async () => {
    try {
      const accountInfoRaw = await api.query.system.account(currentAccount.address);
      const accountInfo = JSON.parse(accountInfoRaw)
      const balance = (parseInt(accountInfo.data.free) / DECIMALS).toFixed(2).replace(/[.,]00$/, "")
      setBalance(balance);
    } catch (err) {
      console.log("Error when getting the account balance")
      console.log("error: ", err)
    }
  }


  const checkStakingInfoPool = async () => {
    if (isConnected) {
      // const entries = await api.query.nominationPools.poolMembers.entries()
      const poolMembersRaw = await api.query.nominationPools.poolMembers(currentAccount.address)
      const poolMembers = JSON.parse(JSON.stringify(poolMembersRaw));

      if (poolMembers !== null) {
        const currentEra = await api.query.staking.currentEra()
        setPoolId(poolMembers.poolId)
        // setPoolName(getPoolNameFromId(poolMembers.poolId))
        const stakedPoolBal = (poolMembers.points / DECIMALS).toFixed(2).replace(/[.,]00$/, "")
        setStakedBalancePool(Number(stakedPoolBal))
        if (Object.keys(poolMembers.unbondingEras).length !== 0) {
          let eras = []
          let amount = []
          let amountWhithdrawable = 0;
          for (let i = 0; i < Object.keys(poolMembers.unbondingEras).length; i++) {
            const erasTotal = Object.keys(poolMembers.unbondingEras)[i]
            const amountAux = (Number(poolMembers.unbondingEras[erasTotal]) / DECIMALS).toFixed(2).replace(/[.,]00$/, "")
            if (Number(erasTotal) - Number(currentEra) > 0) {
              eras.push(Number(erasTotal) - Number(currentEra))
              amount.push(amountAux)
            } else { // si las eras para desstake son 0 o menos ya se puede cobrara y se suma a withdraw amount
              amountWhithdrawable += Number(amountAux)
            }
          }
          setUnboundingErasPool(eras)
          setUnboundingAmountPool(amount)
          setAmountToWithdrawPool(amountWhithdrawable)
          setIsWithdrawUnbondedPool(() => { return amountWhithdrawable !== 0 })
          // setAmountToWithdrawPool(100)
          // setIsWithdrawUnbondedPool(true)
        }
      }

      // const pendingRewardsPool = await api.call.nominationPoolsApi.pendingRewardsPool(account.address)
      let pendingRewardsPool = await api.call.nominationPoolsApi.pendingRewards(currentAccount.address)
      // Si hay pendingRewardsPool
      if (!pendingRewardsPool.isZero()) {
        pendingRewardsPool = (Number(pendingRewardsPool) / DECIMALS).toFixed(2).replace(/[.,]00$/, "")
        setPendingRewardsPool(pendingRewardsPool)
      }
    }
  }

  useEffect(() => {
    getDeviceType()
  }, []);


  const setBalancesToZero = async () => {
    setBalance(0)
    setStakedBalanceNominator(0)
    setStakedBalancePool(0)
    setUnboundingAmountPool([])
    setUnboundingErasPool([])
    setUnboundingAmountNominator([])
    setUnboundingErasNominator([])
    setAmountToWithdrawNominator(0)
    setAmountToWithdrawPool(0)
    setPendingRewardsPool(0)
    setPoolId(0)
    // setPoolName("")
  }

  const calculateAllBalances = async () => {
    setBalancesToZero();
    checkStakingInfoNominator();
    checkStakingInfoPool();
    checkAccountBalance();
  }

  useEffect(() => {
    if (localCurrentAccount !== null) connectToPolkadotAccoount(currentAccount);
    else connectToPolkadotAccoountFirstTime()
  }, []);

  useEffect(() => {
    localStorage.setItem("themeMode", JSON.stringify(themeMode));
  }, [themeMode]);

  useEffect(() => {
    if (isConnected && currentAccount) {
      calculateAllBalances();
    }
  }, [isConnected, currentAccount, isConnectButtonMarked]);

  useEffect(() => {
    if (isConnected && azeroPrice === 0) {
      getAzeroPrice();
    }
  }, [isConnected, currentAccount, isConnectButtonMarked]);

  return (
    <PolkadotContext.Provider
      value={{
        themeMode, setThemeMode,
        extensionsNotInstalled,
        isConnectButtonMarked, setIsConnectButtonMarked,
        isMainnet, setIsMainnet,
        isAndroidOrIos,

        api,
        injector,
        // connectToPolkadot,
        disconnectFromPolkadot,
        isConnected,
        balance,
        azeroPrice, azeroPriceChange,
        allAccounts,
        currentAccount,
        selectedWalletExt, setSelectedWalletExt,

        stakedBalanceNominator,
        unboundingErasNominator, unboundingAmountNominator,
        isWithdrawUnbondedNominator, amountToWithdrawNominator,
        validatorAsociatedAccounts,
        payeeNominator,

        stakedBalancePool,
        unboundingAmountPool,
        unboundingErasPool,
        isWithdrawUnbondedPool, amountToWithdrawPool,
        pendingRewardsPool, setPendingRewardsPool,
        poolId,
        // poolName,

        calculateAllBalances,
        connectToPolkadotAccoount,
        getAccounts,
        setBalancesToZero,
      }}
    >
      {children}
    </PolkadotContext.Provider>
  );
};

export default PolkadotProvider;
