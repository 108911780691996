import { Box } from "@mui/material";
import { styled } from "@mui/system";

const WidgetWrapper = styled(Box)(({ theme, backgroundColor = theme.palette.background.alt, borderColor = theme.palette.background }) => ({
  padding: "1.5rem 1.5rem 0.75rem 1.5rem",
  // backgroundColor: theme.palette.background.alt,
  backgroundColor,
  borderRadius: "2.5rem",
  // borderLeftStyle: "solid",
  // borderRightStyle: "solid",
  border: "0.35rem solid",
  // borderColor: theme.palette.background,
  borderColor
}));

export default WidgetWrapper;
