import React from "react";

import {
  Button,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LinkOffIcon from '@mui/icons-material/LinkOff';



const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export default function WalletConnected({ isConnected, accountsFiltered, currentAccount, connectToPolkadotAccoount, disconnectFromPolkadot, setIsConnectButtonMarked, setIsMobileMenuToggled, isNonMobileScreens }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const hooverBackground = theme.palette.connectButton.hooverBackground;
  const background = theme.palette.connectButton.background;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnectWallet = async () => {
    await disconnectFromPolkadot();
    setAnchorEl(null);
    if (!isNonMobileScreens) setIsMobileMenuToggled(false)
  };

  const handleChangeCurrentAccount = (newAccount) => {
    connectToPolkadotAccoount(newAccount);
    setAnchorEl(null);
  };


  return (

    <>
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            backgroundColor: background,
            margin: "10px 0",
            "&:hover": {
              backgroundColor: hooverBackground,
              cursor: "pointer",
            },
          }}
        >
          {isConnected && currentAccount.address.slice(0, 4) + "..." + currentAccount.address.slice(44, 48)}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {accountsFiltered && accountsFiltered.map((element, idx) => (
            <div key={idx}>
              {element.address !== currentAccount.address &&
                <MenuItem onClick={() => handleChangeCurrentAccount(element)} disableRipple>
                  {element.address.slice(0, 4) + "..." + element.address.slice(44, 48)}
                </MenuItem>
              }
            </div>
          ))}
          {accountsFiltered && accountsFiltered.length > 1 && <Divider sx={{ my: 0.5 }} />}

          <MenuItem onClick={handleDisconnectWallet} disableRipple sx={{ display: "felx", justifyContent: "center" }}>
            <LinkOffIcon />
            Disconnect
          </MenuItem>
        </StyledMenu>
      </div >
    </>
  );
}