import React, { useState, useEffect } from "react";
import { Typography, useTheme, Button, Box, alpha } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import AzeroImg from "components/AzeroImg";
import TypingEffect from "components/TypingEffect";
import { StakeInfo } from "components/staking";

export const VaultsNominator = ({
  mobileFontSize,
  isNonSmallMobileScreens,
  transferable,
  azeroPrice, azeroPriceChange,
  balance,
  stakedBalancePool,
  pendingRewardsPool,
  unboundingErasPool,
  unboundingAmountPool,
  stakedBalanceNominator,
  unboundingAmountNominator,
  unboundingErasNominator
}) => {
  const { palette } = useTheme();

  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;

  const totalBalance = Number(balance) + Number(stakedBalancePool) + unboundingAmountPool.reduce((a, b) => Number(a) + Number(b), 0)

  return (
    <WidgetWrapper backgroundColor={background} borderColor={border}>
      <FlexBetween sx={{ alignItems: "start" }} >
        <Box pb="1rem">
          <Typography color={colorTittle}
            fontSize={!isNonSmallMobileScreens && "12px"}
            variant="h3"
            fontWeight="900">
            <TypingEffect text="Vault" typingSpeed={200} />
          </Typography>
        </Box>
      </FlexBetween>

      <FlexBetween >
        <FlexBetween sx={{ alignItems: "start" }}>
          <Box>
            <Typography color={colorMain} variant="h6" fontSize={!isNonSmallMobileScreens && mobileFontSize} fontWeight="500">
              Balance:&nbsp;
            </Typography>
          </Box>

          <Box>
            <Typography color={colorBalance} fontSize={!isNonSmallMobileScreens && "16px"} variant="h2" fontWeight="900">
              {totalBalance.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              <AzeroImg width={isNonSmallMobileScreens ? "26px" : "15px"} />
            </Typography>
            <Box sx={{ marginTop: "-3px", paddingRight: "33px" }} display="flex" alignItems="center" justifyContent="end">
              <Typography sx={{ color: alpha(colorBalance, 0.7) }} variant="h4" fontSize={!isNonSmallMobileScreens && "12px"} fontWeight="500">
                ${(azeroPrice * totalBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
              {/* <AttachMoneyIcon /> */}
            </Box>
          </Box>

        </FlexBetween>

      </FlexBetween>

      <Box p="5px" />

      <StakeInfo
        padding={""}
        isNonSmallMobileScreens={isNonSmallMobileScreens}
        text={"Available:"}
        lettersColor={colorMain}
        numbersColor={colorSecondary}
        mobileFontSize={mobileFontSize}
        amount={transferable}
      />

      <Box p="5px" />
      {/* NOMINATOR */}
      <FlexBetween pl={"5px"}>
        <FlexBetween>
          <Typography color={colorMain} fontSize={!isNonSmallMobileScreens && mobileFontSize} fontWeight="900" >
            <TypingEffect text="Nominator" typingSpeed={200} />
          </Typography>
        </FlexBetween>
      </FlexBetween>

      <StakeInfo
        padding={"0 0 0 10px"}
        isNonSmallMobileScreens={isNonSmallMobileScreens}
        text={"Staked:"}
        lettersColor={colorMain}
        numbersColor={colorSecondary}
        mobileFontSize={mobileFontSize}
        amount={stakedBalanceNominator}
      />

      {stakedBalanceNominator !== 0 &&
        <>
          {unboundingAmountNominator !== undefined && unboundingAmountNominator.length !== 0 &&

            unboundingAmountNominator.map(function (element, i) {
              return (
                <div key={`unboundingAmountNominator${i}`} >
                  <StakeInfo
                    padding={"0 0 0 10px"}
                    isNonSmallMobileScreens={isNonSmallMobileScreens}
                    text={`Unstaking (${unboundingErasNominator[i]} days):`}
                    lettersColor={colorMain}
                    numbersColor={colorSecondary}
                    mobileFontSize={mobileFontSize}
                    amount={unboundingAmountNominator[i]}
                  />
                </div>
              );
            })
          }

        </>
      }

      {/* POOL */}
      <Box p="5px" />
      <FlexBetween pl={"5px"}>
        <FlexBetween>
          <Typography color={colorMain} fontWeight="900" fontSize={!isNonSmallMobileScreens && mobileFontSize}>
            <TypingEffect text="Pool" typingSpeed={200} />
          </Typography>
        </FlexBetween>
      </FlexBetween>

      <StakeInfo
        padding={"0 0 0 10px"}
        isNonSmallMobileScreens={isNonSmallMobileScreens}
        text={"Staked:"}
        lettersColor={colorMain}
        numbersColor={colorSecondary}
        mobileFontSize={mobileFontSize}
        amount={stakedBalancePool}
      />

      <StakeInfo
        padding={"0 0 0 10px"}
        isNonSmallMobileScreens={isNonSmallMobileScreens}
        text={"Claimable:"}
        lettersColor={colorMain}
        numbersColor={colorSecondary}
        mobileFontSize={mobileFontSize}
        amount={pendingRewardsPool}
      />

      {stakedBalancePool !== 0 &&
        <>
          {unboundingAmountPool !== undefined && unboundingAmountPool.length !== 0 &&

            unboundingAmountPool.map(function (element, i) {
              return (
                <div key={`unboundingAmountPool${i}`} >
                  <StakeInfo
                    padding={"0 0 0 10px"}
                    isNonSmallMobileScreens={isNonSmallMobileScreens}
                    text={`Unstaking (${unboundingErasPool[i]} days):`}
                    lettersColor={colorMain}
                    numbersColor={colorSecondary}
                    mobileFontSize={mobileFontSize}
                    amount={unboundingAmountPool[i]}
                  />
                </div>
              );
            })
          }

        </>
      }



    </WidgetWrapper>
  );
};
