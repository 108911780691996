import React, { useState, useEffect } from 'react'

const TypingEffect = ({ text = '', typingSpeed = 100 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const typing = () => {
      if (charIndex < text.length) {
        setDisplayedText((prevDisplayedText) => prevDisplayedText + text[charIndex]);
        setCharIndex(charIndex + 1);
      }
    };

    const typingTimeout = setTimeout(typing, typingSpeed);
    return () => clearTimeout(typingTimeout);
  }, [text, typingSpeed, charIndex]);

  return (
    <>
      {displayedText}
    </>
  );
};

export default TypingEffect