import React from "react";
import PropTypes from 'prop-types'

import { Box, Typography, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


export const StakeButton = ({ text, stakingLoading, isNonMobileScreens, backgroundColor, hooverBackgroundColor, textColor, onClick }) => {

  return (
    <Box >
      {stakingLoading === true ? (
        <LoadingButton
          loading
          loadingPosition="center"
          startIcon={<SaveIcon />}
          variant="outlined"
          size={isNonMobileScreens ? "large" : "small"}
          sx={{ backgroundColor: backgroundColor, color: textColor, borderRadius: "2rem" }}
        >
        </LoadingButton>
      ) : (
        <>
          <Button
            variant="contained"
            onClick={onClick}
            disabled={stakingLoading}
            size={isNonMobileScreens ? "large" : "small"}
            sx={{
              backgroundColor: backgroundColor,
              color: textColor,
              borderRadius: "2rem",
              "&:hover": {
                backgroundColor: hooverBackgroundColor,
                cursor: "pointer",
              },
            }}
          >
            <Typography fontWeight={"900"} fontSize={isNonMobileScreens ? "12px" : "8px"}>{text}</Typography>
          </Button>
        </>
      )}
    </Box>

  )
}


StakeButton.propTypes = {
  text: PropTypes.string.isRequired,
  stakingLoading: PropTypes.bool.isRequired,
  isNonMobileScreens: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  hooverBackgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

