import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { Typography, useTheme } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';


const CustomSwitch = ({ isNonMobileScreens, restakingSwitch, handleChangeRestakingSwitch }) => {

  const { palette } = useTheme();
  const hooverBackground = palette.connectButton.hooverBackground;
  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;
  const textFIeld = palette.widgets.textFIeld;

  const ballWidth = isNonMobileScreens ? 42 : 23;
  const ballHeight = isNonMobileScreens ? 26 : 15;
  const backgorundSize = isNonMobileScreens ? 22 : 11;
  const translate = isNonMobileScreens ? 'translateX(16px)' : 'translateX(8px)';

  const IosSwitch = styled((props) => (

    <Switch
      checked={restakingSwitch}
      onChange={handleChangeRestakingSwitch}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple {...props} />
  ))(({ theme }) => ({
    width: ballWidth,
    height: ballHeight,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: translate,
        color: colorBalance,
        '& + .MuiSwitch-track': {
          backgroundColor: textFIeld,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: textFIeld,
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: backgorundSize,
      height: backgorundSize,
      color: colorBalance,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));


  return (

    <FormControlLabel
      control={<IosSwitch
        sx={{ m: 1 }}
      // defaultChecked
      />}
    // label="iOS style"
    />
  )
}

export default CustomSwitch