import React from "react";
import {
  MenuItem,
  Modal,
  Typography,
  useTheme,
  Box,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import PolkadotjsLogo from "assets/img/wallet/PolkadotjsLogo.svg";
import SubWalletLogo from "assets/img/wallet/SubWalletLogo.svg";
import TalismanLogo from "assets/img/wallet/TalismanLogo.png";
import NovaLogo from "assets/img/wallet/NovaLogo.png";

import FlexBetween from "components/FlexBetween";

export default function WalletSelectAccountMenu({ isOpen, setIsOpen, accountsFiltered, connectToPolkadotAccoount, setIsMobileMenuToggled, isNonMobileScreens, isAndroidOrIos }) {
  const theme = useTheme();
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonSmallMobileScreens = useMediaQuery("(min-width:550px)");
  const neutralLight = theme.palette.neutral.light;

  function onClickHandler(account) {
    connectToPolkadotAccoount(account)
    setIsOpen(false)
    if (!isNonMobileScreens) setIsMobileMenuToggled(false)
  }

  const boxWidth = isNonSmallMobileScreens ? '500px' : "150px"

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: boxWidth,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(isOpen);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Modal
        open={isOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            id="modal-modal-title"
            variant={isNonSmallMobileScreens ? "h4" : "h6"}
            component="h2"
            sx={{ padding: "1rem", display: "flex", justifyContent: "center", fontWeight: "600" }}>

            {accountsFiltered && accountsFiltered.length === 0 ?
              ("No accounts found")
              : (
                "Choose Account"
              )}
          </Typography>
          {accountsFiltered && accountsFiltered.map((acct, idx) => (
            <MenuItem
              key={idx}
              aria-label={"button_" + idx}
              // onClick={handleClose}
              onClick={() => onClickHandler(acct)}
            >
              <FlexBetween gap="0.75rem">
                {isNonSmallMobileScreens &&
                  <img
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                    aria-label={"img_" + idx}
                    width={"30px"}
                    height={"30px"}
                    alt="user"
                    src={
                      acct.source === "polkadot-js" && isAndroidOrIos
                        ? NovaLogo
                        : acct.source === "polkadot-js"
                          ? PolkadotjsLogo
                          : acct.source === "subwallet-js"
                            ? SubWalletLogo
                            : acct.source === "talisman"
                              ? TalismanLogo
                              // : acct.source === "nova"
                              //   ? NovaLogo
                              : ""
                    }
                  />
                }

                {isNonSmallMobileScreens &&
                  <Typography
                    color="neutral"
                  >
                    {acct.name.length > 15 ? (
                      <>{acct.name.slice(0, 15) + "..."}</>
                    ) : (
                      <>{acct.name}</>
                    )}
                  </Typography>
                }

                {isNonSmallMobileScreens ? (
                  <Typography>
                    {acct.address.slice(0, 4) + "..." + acct.address.slice(44, 48)}
                  </Typography>
                ) : (
                  <Typography>
                    {acct.address.slice(0, 4) + "..."}
                  </Typography>
                )}

              </FlexBetween>
            </MenuItem>
          ))}
        </Box>
      </Modal>
    </>
  );
}
