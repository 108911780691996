import React, { useMemo, useState, useContext } from "react";
import { web3Enable } from "@polkadot/extension-dapp";
import { toast } from "react-hot-toast";
import {
  Typography,
  useMediaQuery
} from '@mui/material';
import Link from '@mui/material/Link';

import { toastMessages } from "config/wallet";
import { PolkadotContext } from "providers/PolkadotProvider";
import WalletSelectAccountMenu from "./WalletSelectAccountMenu";
import WalletConnected from "./WalletConnected";
import WalletNotConnected from "./WalletNotConnected";


export default function WalletButton({ isConnectWalletPage = false, setIsMobileMenuToggled }) {

  const isNonMobileScreens = useMediaQuery("(min-width: 680px)");

  const { getAccounts,
    isConnectButtonMarked,
    setIsConnectButtonMarked,
    allAccounts,
    currentAccount,
    isConnected,
    disconnectFromPolkadot,
    selectedWalletExt,
    setSelectedWalletExt,
    connectToPolkadotAccoount,
    isAndroidOrIos,
    extensionsNotInstalled } = useContext(PolkadotContext);

  const [isOpen, setIsOpen] = useState(false);

  const setWalletExtension = async function (walletExt) {
    const extension = await web3Enable(process.env.REACT_APP_NAME);

    // Si el user no tiene wallets instaladas
    if (extension.length === 0) {
      toast.error(
        <Typography>
          {/* {toastMessages.NO_EXTENSION} You may download {walletExt} &nbsp;
          {walletExt === "subwallet-js" && <Link href="https://subwallet.app/download.html">here{" "}</Link>}
          {walletExt === "polkadot-js" && <Link href="https://polkadot.js.org/extension/">here{" "}</Link>}
          {walletExt === "talisman" && <Link href="https://www.talisman.xyz/">here{" "}</Link>} */}
          {toastMessages.NO_EXTENSION} You may download SubWallet &nbsp;<Link href="https://subwallet.app/download.html" target="_blank">here{" "}</Link>
        </Typography>
      );
      return;
    }

    if (!allAccounts.length) {
      window.location.reload()
      // toast.error(toastMessages.NO_ACCOUNT);
      return;
    }

    setIsOpen(true)
  };

  function connectWalletOnClick(walletExt) {

    if (!walletExt) return toast.error("Wallet Ext error!");
    setSelectedWalletExt(walletExt);

    try {
      // not log any account yet -> open window popup
      if (!currentAccount) {
        setWalletExtension(walletExt);
        return;
      }
    } catch (error) {
      console.log("@_@ error", error);
    }
    // setIsOpen(false)
  }

  const accountsFiltered = useMemo(() => {
    if (selectedWalletExt) {
      return allAccounts.filter((i) => i.source === selectedWalletExt);
    }
    else { return null }
  }, [allAccounts, selectedWalletExt]);


  return (
    <>
      <WalletSelectAccountMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        accountsFiltered={accountsFiltered}
        connectToPolkadotAccoount={connectToPolkadotAccoount}
        setIsMobileMenuToggled={setIsMobileMenuToggled}
        isNonMobileScreens={isNonMobileScreens}
        isAndroidOrIos={isAndroidOrIos}
      />

      {!currentAccount ? (
        <WalletNotConnected
          isConnectWalletPage={isConnectWalletPage}
          getAccounts={getAccounts}
          isConnected={isConnected}
          isConnectButtonMarked={isConnectButtonMarked}
          setIsConnectButtonMarked={setIsConnectButtonMarked}
          onClick={connectWalletOnClick}
          isAndroidOrIos={isAndroidOrIos}
          extensionsNotInstalled={extensionsNotInstalled}
        />
      ) : (
        <WalletConnected
          isConnected={isConnected}
          accountsFiltered={accountsFiltered}
          connectToPolkadotAccoount={connectToPolkadotAccoount}
          currentAccount={currentAccount}
          disconnectFromPolkadot={disconnectFromPolkadot}
          setIsConnectButtonMarked={setIsConnectButtonMarked}
          setIsMobileMenuToggled={setIsMobileMenuToggled}
          isNonMobileScreens={isNonMobileScreens}
        />
      )}
    </>
  );
}

