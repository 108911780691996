import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';

import { supportWallets } from "config/wallet";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from 'components/WidgetWrapper';
import TypingEffect from 'components/TypingEffect';
import DevnetOrMainnet from 'components/DevnetOrMainnet';
import PolkadotjsLogo from "assets/img/wallet/PolkadotjsLogo.svg";
import SubWalletLogo from "assets/img/wallet/SubWalletLogo.svg";
import TalismanLogo from "assets/img/wallet/TalismanLogo.png";
import NovaLogo from "assets/img/wallet/NovaLogo.png";



export default function WalletNotConnected({ isConnectWalletPage, isConnected, onClick, isConnectButtonMarked, setIsConnectButtonMarked, getAccounts, isAndroidOrIos, extensionsNotInstalled }) {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  // const isNonMobileScreens = useMediaQuery("(min-width:800px)");
  const isNonSmallMobileScreens = useMediaQuery("(min-width:400px)");
  const dark = theme.palette.primary.dark;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { palette } = useTheme();
  const background = palette.connectButton.background;
  const hooverBackground = palette.connectButton.hooverBackground;
  const color = palette.connectButton.color;
  const border = palette.connectButton.border;

  const handleClick = (event) => {
    getAccounts()
    setAnchorEl(event.currentTarget);
    setIsConnectButtonMarked(null)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsConnectButtonMarked(null)
  };

  useEffect(() => {
    if (Boolean(isConnectButtonMarked) && !isConnected) {
      getAccounts()
      setAnchorEl(isConnectButtonMarked)
      setIsConnectButtonMarked(null)
    }
  }, [isConnectButtonMarked]);

  return (
    <div>
      {isConnectWalletPage ?
        (
          <>
            {isNonMobileScreens ? (
              <Box sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Box >
                  <WidgetWrapper
                    onClick={handleClick}
                    backgroundColor={background}
                    borderColor={border}
                    m={"150px"}
                    width={isNonMobileScreens ? "600px" : "calc(100hh-100px)"}
                    height={isNonMobileScreens ? "300px" : "200px"}
                    sx={{
                      // width: "600px",
                      // height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: color,
                      "&:hover": {
                        backgroundColor: hooverBackground,
                        cursor: "pointer",
                        // color: "#000000",
                      },
                    }}
                  // {isNonMobileScreens ? sx={{width: "600px"}} : sx={{width: "calc(100hh-100px)" }}}
                  >
                    < Box >
                      <Typography variant='h3'>
                        <TypingEffect text="Connect your wallet" typingSpeed={100} />
                      </Typography>
                    </Box >

                  </WidgetWrapper>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DevnetOrMainnet />
                  </Box>
                </Box>
              </Box >
            ) : (
              <Box>
                <Box
                  paddingLeft={isNonSmallMobileScreens ? '70px' : "30px"}
                  sx={{
                    // paddingLeft: {isNonSmallMobileScreens ? '70px' : "30px"},
                    position: "absolute",
                    top: "40%",
                    // width: 'calc(100vw - 100px )',
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "left",
                    alignItems: "start",

                  }}>
                  <Typography
                    variant={isNonSmallMobileScreens ? 'h3' : "h5"}
                    sx={{ paddingLeft: "10px" }}>
                    <TypingEffect text="Connect" typingSpeed={100} />
                  </Typography>

                  <Typography
                    variant={isNonSmallMobileScreens ? 'h3' : "h5"}
                    sx={{ paddingBottom: "10px", paddingLeft: "10px" }}>
                    <TypingEffect text="your wallet" typingSpeed={100} />
                  </Typography>

                  <WidgetWrapper
                    onClick={handleClick}
                    backgroundColor={background}
                    borderColor={background}
                    height={isNonSmallMobileScreens ? '60px' : "20px"}
                    width={isNonSmallMobileScreens ? '250px' : "120px"}
                    sx={{
                      // width: "calc(100vw-100px)",
                      // height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: color,
                      "&:hover": {
                        backgroundColor: hooverBackground,
                        cursor: "pointer",
                        color: "#000000",
                      },
                    }}
                  >
                    <Typography
                      pb={"10px"}
                      variant={isNonSmallMobileScreens ? 'h4' : "h6"}
                    >
                      <TypingEffect text="Connect" typingSpeed={100} />
                    </Typography>
                  </WidgetWrapper>

                  <Box
                    sx={{
                      // paddingTop: "100px",
                      display: "flex",
                      justifyContent: "center",
                      position: 'fixed',
                      left: 0,
                      right: 0,
                      bottom: '150px',
                    }}>
                    <DevnetOrMainnet isNonSmallMobileScreens={isNonSmallMobileScreens} />
                  </Box>
                </Box>

              </Box >

            )}
          </>
        ) : (
          <>
            <Button
              id="demo-positioned-button"
              variant="contained"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              // sx={isConnectButtonMarked ? {
              //   border: "0.35rem solid",
              //   // transition: "borderWidth 0.2s linear",
              //   animation: 'border-animation 3s infinite'
              //   // borderStyle: "dashed",
              // } : { border: "" }}
              sx={{
                backgroundColor: background,
                "&:hover": {
                  backgroundColor: hooverBackground,
                  cursor: "pointer",
                },
              }}
            >
              Connect wallet
            </Button>

          </>
        )

      }
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {supportWallets
          .filter((item) => { // si estamos en android o ios no mostramos talisman
            if (item.name === "Talisman" && isAndroidOrIos) return null;
            return item
          })
          .map((item, idx) => (

            <MenuItem
              key={idx}
              onClick={() => onClick(item.extensionName)}
              sx={extensionsNotInstalled?.includes(item.extensionName) ? { opacity: "0.2" } : {}}
            >

              <FlexBetween gap="0.75rem">
                <img
                  style={{ objectFit: "cover", borderRadius: "50%" }}
                  width={"30px"}
                  height={"30px"}
                  alt="user"
                  src={
                    item.extensionName === "polkadot-js" && isAndroidOrIos
                      ? NovaLogo
                      :
                      item.extensionName === "polkadot-js"
                        ? PolkadotjsLogo
                        : item.extensionName === "subwallet-js"
                          ? SubWalletLogo
                          : item.extensionName === "talisman"
                            ? TalismanLogo
                            // : item.extensionName === "nova"
                            //   ? NovaLogo
                            : ""
                  }
                />

                <Typography
                  // fontWeight="bold"
                  color="neutral"
                >
                  {isAndroidOrIos && item.name === "Polkadot JS" ? "Nova" : item.name}
                </Typography>

              </FlexBetween>
            </MenuItem>
          ))}
      </Menu>

    </div>
  );
}
