import React, { useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { toast } from "react-hot-toast";

import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { DECIMALS } from "config/constants"
// import { POOL_A_ID, POOL_B_ID, POOL_C_ID, POOL_A_NAME, POOL_B_NAME, POOL_C_NAME } from 'config/config';
import TypingEffect from "components/TypingEffect";
import { StakeButton, StakeTextField } from "components/staking";

export const FirstStakePool = ({ api, account, isConnected, stakedBalancePool, poolId, injector, pendingRewardsPool, calculateAllBalances }) => {
  const isNonMobileScreens = useMediaQuery("(min-width:600px)");
  const widthAmountBox = isNonMobileScreens ? "12rem" : "5rem"

  const [stakingLoadingPool, setStakingLoadingPool] = useState(false);
  const [stakingAmountPool, setStakingAmountPool] = useState("");

  const { palette } = useTheme();
  const hooverBackground = palette.connectButton.hooverBackground;
  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;
  const textFIeld = palette.widgets.textFIeld;

  // const [selectedPoolName, setSelectedPoolName] = useState("");
  // const [selectedPoolId, setSelectedPoolId] = useState(0);

  // const handleCheckBoxA = (event) => {
  //   setSelectedPoolName(POOL_A_NAME);
  //   setSelectedPoolId(POOL_A_ID);
  // };

  // const handleCheckBoxB = (event) => {
  //   setSelectedPoolName(POOL_B_NAME);
  //   setSelectedPoolId(POOL_B_ID);
  // };

  // const handleCheckBoxC = (event) => {
  //   setSelectedPoolName(POOL_C_NAME);
  //   setSelectedPoolId(POOL_C_ID);
  // };




  const handleStakeButtonPool = async () => {

    if (!isConnected) {
      toast.error("Connect a wallet first!");
      return;
    }

    if (poolId !== process.env.REACT_APP_POOL_ID && poolId !== 0) {
      toast.error(`This Account (${account.address.slice(0, 4) + "..." + account.address.slice(44, 48)}) is participating in pool ${process.env.REACT_APP_POOL_ID}. You must create a new account to participate in this pool.`);
      return;
    }

    // if (selectedPoolName.length === 0) {
    //   toast.error("Select pool first!");
    //   return;
    // }

    if (stakingAmountPool < 10) {
      toast.error("Minimum staking amount is 10 AZERO");
      return;
    }

    setStakingLoadingPool(true)

    try {
      // Bond more funds if account is a nominator
      // const tx = await api.tx.nominationPools.join(Number(stakingAmountPool) * DECIMALS, selectedPoolId).signAndSend(account.address, { signer: injector.signer });
      const tx = await api.tx.nominationPools.join(BigInt(Number(stakingAmountPool) * DECIMALS), 66).signAndSend(account.address, { signer: injector.signer });
      console.log("tx: ", tx.toString())
      await new Promise(r => setTimeout(r, 3000));
      calculateAllBalances()

    } catch (err) {
      console.log("Error when bonding: ", err)
      setStakingLoadingPool(false)
    }
    setStakingLoadingPool(false)
  }


  return (
    <Box aria-label="FirstSakeInAPool">
      <WidgetWrapper backgroundColor={background} borderColor={border}>

        <FlexBetween color={colorTittle} fontSize={isNonMobileScreens ? "1.5rem" : "12px"} sx={{ justifyContent: "left", fontWeight: "900" }}>
          <TypingEffect text="First stake in a pool" typingSpeed={200} />
        </FlexBetween>
        <FlexBetween
          pb="10px"
          color={colorTittle}
          fontSize={isNonMobileScreens ? "1rem" : "10px"}
          sx={{ justifyContent: "left" }}
        >
          Min &nbsp;<span style={{ fontWeight: "900" }}>10</span> &nbsp; Azeros
        </FlexBetween>

        {/* SELECT POOL  */}
        {/* <FlexBetween p="10px">
          <Typography>
            Select pool
          </Typography>
          <FormControlLabel
            control={<Checkbox onChange={handleCheckBoxA} checked={selectedPoolName === POOL_A_NAME} />}
            label={POOL_A_NAME}
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Checkbox onChange={handleCheckBoxB} checked={selectedPoolName === POOL_B_NAME} />}
            label={POOL_B_NAME}
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Checkbox onChange={handleCheckBoxC} checked={selectedPoolName === POOL_C_NAME} />}
            label={POOL_C_NAME}
            labelPlacement="end"
          />
        </FlexBetween> */}

        {/* STAKE */}
        <FlexBetween p={isNonMobileScreens ? "10px" : ""} gap="10px" sx={{ display: "flex", alignItems: "end" }}>

          <StakeTextField
            text={"Amount to stake:"}
            isNonMobileScreens={isNonMobileScreens}
            descriptionTextColor={colorBalance}
            inputTextColor={colorBalance}
            inputBackgroundColor={textFIeld}
            stakingAmountPool={stakingAmountPool}
            setStakingAmountPool={setStakingAmountPool}
            minAmount={"10"}
          />

          <StakeButton
            text={"Stake"}
            stakingLoading={stakingLoadingPool}
            isNonMobileScreens={isNonMobileScreens}
            backgroundColor={textFIeld}
            hooverBackgroundColor={hooverBackground}
            textColor={colorBalance}
            onClick={handleStakeButtonPool}
          />

        </FlexBetween>

      </WidgetWrapper>
    </Box>
  );
};
