import React, { useContext, useEffect } from "react";

import {
  Button,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { PolkadotContext } from "providers/PolkadotProvider";



const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 20,
    marginTop: theme.spacing(1),
    fontSize: 100,
    // minWidth: 120,
    // maxHeight: '50px',
    // overflow: 'auto',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0',
      margin: '0',
      // height: 'px',
    },
    '& .MuiMenuItem-root': {
      fontSize: '12px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export default function DevnetOrMainnet({ isNonSmallMobileScreens }) {

  const [netButton, setNetButton] = React.useState(null);
  const open = Boolean(netButton);

  const theme = useTheme();
  const hooverBackground = theme.palette.connectButton.hooverBackground;
  const background = theme.palette.connectButton.background;


  const { isMainnet, setIsMainnet } = useContext(PolkadotContext);

  const handleClick = (event) => {
    setNetButton(event.currentTarget);
  };
  const handleClose = () => {
    setNetButton(null);
  };

  const handleChangeNetwork = async () => {
    await setIsMainnet(prevIsMainnet => !prevIsMainnet);
    setNetButton(null);
  };


  return (

    <>
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          // size={isNonSmallMobileScreens ? "large" : "small"}
          size={"small"}
          sx={{
            paddingTop: "5px",
            fontSize: "12px",
            borderRadius: "2rem",
            backgroundColor: background,
            "&:hover": {
              backgroundColor: hooverBackground,
              cursor: "pointer",
            },
          }}
        >
          {isMainnet ? ("Mainnet") : (<>&nbsp;{"Testnet"}</>)}
        </Button>
        <StyledMenu
          id="demo-customized-menu-mainnet-button"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={netButton}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={handleChangeNetwork}
            disableRipple
            sx={{
              display: "felx",
              justifyContent: "center"
            }}>
            {isMainnet ? ("Testnet") : ("Mainnet")}
          </MenuItem>
        </StyledMenu>
      </div >
    </>
  );
}