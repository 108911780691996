import React from "react";
import { useMediaQuery } from "@mui/material";

import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const StakingPieChart = ({ data, themeMode }) => {
  const isNonSmallMobileScreens = useMediaQuery("(min-width:600px)");

  let COLORS;
  if (themeMode === "dark") {
    COLORS = ['#8fffd7', '#3f485f', '#6a96d6', '#65B167'];
  } else {
    COLORS = ['#8fffd7', '#d8dbee', '#8694b2', '#65B167'];
  }

  const legendTextFormatter = (value, entry, index) => {
    let color;
    if (themeMode === "dark") color = "#3f485f"
    else color = "#354e77"
    return (
      <span style={{ color: color }}>
        {value}
      </span>
    );
  };

  let innerRadius = 40
  let outerRadius = 100
  let height = 300
  let width = "50%"
  if (!isNonSmallMobileScreens) {
    innerRadius = 20
    outerRadius = 50
    height = 200
    width = "30%"
  }

  return (
    <div>
      <ResponsiveContainer
        width="100%"
        // height="100%"
        height={height}
      // width={width}
      // minHeight={300}
      // minWidth={300}
      >
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            minAngle={3}
            // innerRadius={innerRadius}
            outerRadius={outerRadius}
            paddingAngle={3}
            // fill="#3f485f"
            dataKey="value"
            stroke={themeMode === "dark" ? "#3f485f" : "#d8dbee"}
            legendType="circle"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            // layout="vertical" align="right" verticalAlign="middle" 
            formatter={legendTextFormatter}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StakingPieChart;



// import { Doughnut } from 'react-chartjs-2';
// import { Chart } from 'chart.js';
// import { ArcElement, DoughnutController } from 'chart.js';
// // Register the required controller and elements
// Chart.register(DoughnutController, ArcElement);

// const DonutChart = ({ spent, available, saves }) => {
//   const data = {
//     labels: ['Spent', 'Available', 'Saves'],
//     datasets: [
//       {
//         data: [spent, available, saves],
//         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
//         hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
//       }
//     ]
//   };

//   const options = {
//     cutoutPercentage: '50%',// This creates the donut hole
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         display: true,
//         position: 'bottom',
//       },
//     },
//   };

//   return <Doughnut data={data} options={options} />;
// };