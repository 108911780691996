import React, { useContext, useState, useEffect } from "react";
import { Box, useMediaQuery, Typography, TextField, Button } from "@mui/material";
import { PolkadotContext } from "providers/PolkadotProvider";
import { FirstStakePool, StakePool, FirstStakeNominator, StakeNominator, VaultsNominator } from "components/staking"

import Charts from "components/chart";
import { Profits } from "components/staking/Profits";


const NominatorPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1500px)");
  const isNonSmallMobileScreens = useMediaQuery("(min-width:600px)");
  const mobileFontSize = "8px";

  // isNonMobileScreens and isNonSmallMobileScreens === true
  let margin = undefined
  let maxWidth = "32%"
  // Is in the middle of isNonMobileScreens and isNonSmallMobileScreens
  if (!isNonMobileScreens && isNonSmallMobileScreens) {
    margin = "auto"
    maxWidth = "75%"
  }
  if (!isNonMobileScreens && !isNonSmallMobileScreens) {
    margin = undefined
    maxWidth = undefined
  }



  const {
    api,
    injector,
    currentAccount,
    isConnected,
    isMainnet,
    balance,
    azeroPrice, azeroPriceChange,
    calculateAllBalances,

    stakedBalanceNominator,
    payeeNominator,
    isWithdrawUnbondedNominator,
    amountToWithdrawNominator,
    unboundingErasNominator,
    unboundingAmountNominator,
    validatorAsociatedAccounts,

    stakedBalancePool,
    unboundingAmountPool,
    unboundingErasPool,
    isWithdrawUnbondedPool,
    amountToWithdrawPool,
    pendingRewardsPool,
    setPendingRewardsPool,
    poolId,
  } = useContext(PolkadotContext);

  const [balanceTransferable, setBalanceTransferable] = useState(0);
  useEffect(() => {
    setBalanceTransferable(Number((balance - stakedBalanceNominator - amountToWithdrawNominator - unboundingAmountNominator.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2).replace(/[.,]00$/, "")))
  }, [currentAccount, balance, stakedBalanceNominator, amountToWithdrawNominator, unboundingAmountNominator, stakedBalancePool, unboundingAmountPool, amountToWithdrawPool, setPendingRewardsPool]);

  return (
    <Box>
      <Box
        width="100%"
        padding={isNonSmallMobileScreens ? "2rem 6% 0 6%" : "0 6% 0 6%"}
        mt={isNonMobileScreens ? undefined : "1rem"}
        display={isNonMobileScreens ? "flex" : "block"}
        // display={"flex"}
        gap="2rem"
        justifyContent="center"
      >
        <Box
          flexBasis={isNonMobileScreens ? "32%" : undefined}
          maxWidth={maxWidth}
          ml={margin}
          mr={margin}
        >
          <Box>
            <VaultsNominator
              mobileFontSize={mobileFontSize}
              isNonSmallMobileScreens={isNonSmallMobileScreens}
              transferable={balanceTransferable}
              azeroPrice={azeroPrice}
              azeroPriceChange={azeroPriceChange}
              balance={balance}
              stakedBalancePool={stakedBalancePool}
              pendingRewardsPool={pendingRewardsPool}
              unboundingAmountPool={unboundingAmountPool}
              unboundingErasPool={unboundingErasPool}
              stakedBalanceNominator={stakedBalanceNominator}
              unboundingAmountNominator={unboundingAmountNominator}
              unboundingErasNominator={unboundingErasNominator}
            />
            <Box padding={isNonMobileScreens ? "15px" : "5px"}></Box>

            <Charts
              transferable={balanceTransferable}
              stakedBalance={Number(stakedBalancePool) + Number(stakedBalanceNominator)}
              unboundingAmountPool={(unboundingAmountPool.reduce((a, b) => Number(a) + Number(b), 0) + unboundingAmountNominator.reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2)}
              pendingRewardsPool={pendingRewardsPool}
            />
            <Box padding={isNonMobileScreens ? "15px" : "5px"}></Box>
          </Box>
        </Box>

        <Box
          flexBasis={isNonMobileScreens ? "32%" : undefined}
          maxWidth={maxWidth}
          ml={margin}
          mr={margin}
        >
          <>
            {stakedBalanceNominator === 0 ? (
              <FirstStakeNominator
                api={api}
                account={currentAccount}
                isConnected={isConnected}
                balance={balance}
                stakedBalanceNominator={stakedBalanceNominator}
                injector={injector}
                pendingRewardsPool={pendingRewardsPool}
                calculateAllBalances={calculateAllBalances}
              />
            ) : (
              <StakeNominator
                api={api}
                account={currentAccount}
                isConnected={isConnected}
                transferable={balanceTransferable}
                balance={balance}
                stakedBalanceNominator={stakedBalanceNominator}
                payeeNominator={payeeNominator}
                injector={injector}
                pendingRewardsPool={pendingRewardsPool}
                calculateAllBalances={calculateAllBalances}
                isWithdrawUnbonded={isWithdrawUnbondedNominator}
                amountToWithdraw={amountToWithdrawNominator}
              />
            )}
            <Box padding={isNonMobileScreens ? "15px" : "5px"}></Box>

            {stakedBalancePool === 0 ? (
              <FirstStakePool
                api={api}
                account={currentAccount}
                isConnected={isConnected}
                stakedBalancePool={stakedBalancePool}
                poolId={poolId}
                // poolName={poolName}
                injector={injector}
                pendingRewardsPool={pendingRewardsPool}
                calculateAllBalances={calculateAllBalances}
              />
            ) : (
              <StakePool
                api={api}
                account={currentAccount}
                isConnected={isConnected}
                stakedBalancePool={stakedBalancePool}
                poolId={poolId}
                // poolName={poolName}
                injector={injector}
                transferable={balanceTransferable}
                pendingRewardsPool={pendingRewardsPool}
                setPendingRewardsPool={setPendingRewardsPool}
                isWithdrawUnbonded={isWithdrawUnbondedPool}
                amountToWithdraw={amountToWithdrawPool}
                calculateAllBalances={calculateAllBalances}
              />
            )}
            <Box padding={isNonMobileScreens ? "15px" : "5px"}></Box>
          </>

          <Box paddingTop="30px"></Box>
          <Profits
            api={api}
            account={currentAccount}
            isConnected={isConnected}
            injector={injector}
            transferable={balanceTransferable}
            azeroPrice={azeroPrice}
            isNonSmallMobileScreens={isNonSmallMobileScreens}
            isMainnet={isMainnet}
          />




        </Box>

      </Box>
    </Box >
  )

}

export default NominatorPage;