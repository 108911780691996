import { useMemo, useState, useContext } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

import { themeSettings } from "./theme/theme";
import { PolkadotContext } from "providers/PolkadotProvider";
import Navbar from "scenes/navbar";
import PoolPage from "scenes/PoolPage"
import NominatorPage from "scenes/NominatorPage";
import WalletButton from "components/wallet/WalletButton";


function App() {

  const { themeMode } = useContext(PolkadotContext);
  const theme = useMemo(() => createTheme(themeSettings(themeMode)), [themeMode]);

  // const theme = useMemo(() => createTheme(themeSettings("dark")), []);
  const { isConnected, balance, currentAccount } = useContext(PolkadotContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Navbar />

      {isConnected ?
        (
          <div style={{ paddingBottom: "70px" }}>
            {Number(balance) > 2000 ? (
              <NominatorPage />
            ) : (
              <PoolPage />
            )}
          </div>
        ) : (
          <>
            {!currentAccount &&
              <WalletButton isConnectWalletPage={true} />
            }
          </>
        )
      }

    </ThemeProvider>
  );
}

export default App;
