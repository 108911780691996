import React from 'react'
import azeroImage from "assets/img/AlephZero_logo.png"

function AzeroImg({ width = "16px", isNonSmallMobileScreens = true, margin = null }) {

  let styleAux;
  if (isNonSmallMobileScreens) styleAux = { margin: "-10px 0 -2px 3px" }
  else styleAux = { margin: "0 0 -1.5px 2px" }

  if (margin !== null) {
    styleAux = { margin: margin }
  }

  return (
    <img
      width={width}
      // height="auto"
      alt="Azero Logo"
      src={azeroImage}
      style={styleAux}
    />
  )
}

export default AzeroImg