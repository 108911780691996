import React, { useState } from "react";
import { Typography, useTheme, Button, Box, alpha } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import AzeroImg from "components/AzeroImg";
import TypingEffect from "components/TypingEffect";
import { StakeInfo } from "components/staking";

export const VaultsPool = ({
  mobileFontSize,
  isNonSmallMobileScreens,
  transferable,
  azeroPrice, azeroPriceChange,
  balance,
  stakedBalance,
  pendingRewardsPool,
  unboundingErasPool,
  unboundingAmountPool
}) => {
  const { palette } = useTheme();

  const background = palette.widgets.background;
  const border = palette.widgets.border;
  const colorTittle = palette.widgets.colorTittle;
  const colorMain = palette.widgets.colorMain;
  const colorSecondary = palette.widgets.colorSecondary;
  const colorBalance = palette.widgets.colorBalance;

  const totalBalance = Number(balance) + Number(stakedBalance) + unboundingAmountPool.reduce((a, b) => Number(a) + Number(b), 0)

  return (
    <WidgetWrapper backgroundColor={background} borderColor={border}>
      <FlexBetween sx={{ alignItems: "start" }} >
        <Box pb="1rem">
          <Typography color={colorTittle} variant="h3" fontWeight="900" fontSize={!isNonSmallMobileScreens && "12px"}>
            <TypingEffect text="Vault" typingSpeed={200} />
          </Typography>
        </Box>
      </FlexBetween>

      <FlexBetween>
        <FlexBetween sx={{ alignItems: "start" }}>
          <Box pb="0.8rem">
            <Typography color={colorMain} variant="h6" fontSize={!isNonSmallMobileScreens && mobileFontSize} fontWeight="500">
              Balance:&nbsp;
            </Typography>
          </Box>

          <Box>
            <Typography color={colorBalance} fontSize={!isNonSmallMobileScreens && "16px"} variant="h2" fontWeight="900">
              {totalBalance.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              <AzeroImg width={isNonSmallMobileScreens ? "26px" : "15px"} />
            </Typography>
            <Box sx={{ marginTop: "-3px", paddingRight: "33px" }} display="flex" alignItems="center" justifyContent="end">
              <Typography
                sx={{ color: alpha(colorBalance, 0.7) }}
                variant="h4"
                fontSize={!isNonSmallMobileScreens && "12px"}
                fontWeight="500">
                ${(azeroPrice * totalBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
              {/* <AttachMoneyIcon /> */}
            </Box>
          </Box>

        </FlexBetween>

      </FlexBetween>

      <Box p="5px" />

      <StakeInfo
        padding={""}
        isNonSmallMobileScreens={isNonSmallMobileScreens}
        text={"Available:"}
        lettersColor={colorMain}
        numbersColor={colorSecondary}
        mobileFontSize={mobileFontSize}
        amount={transferable}
      />

      <Box p="5px" />

      <StakeInfo
        padding={"0 0 0 10px"}
        isNonSmallMobileScreens={isNonSmallMobileScreens}
        text={"Staked:"}
        lettersColor={colorMain}
        numbersColor={colorSecondary}
        mobileFontSize={mobileFontSize}
        amount={stakedBalance}
      />

      <StakeInfo
        padding={"0 0 0 10px"}
        isNonSmallMobileScreens={isNonSmallMobileScreens}
        text={"Claimable:"}
        lettersColor={colorMain}
        numbersColor={colorSecondary}
        mobileFontSize={mobileFontSize}
        amount={pendingRewardsPool}
      />

      {stakedBalance !== 0 &&
        <>
          {unboundingAmountPool !== undefined && unboundingAmountPool.length !== 0 &&

            unboundingAmountPool.map(function (element, i) {
              return (
                <div key={`unboundingAmountPool${i}`} >
                  <StakeInfo
                    padding={"0 0 0 10px"}
                    isNonSmallMobileScreens={isNonSmallMobileScreens}
                    text={`Unstaking (${unboundingErasPool[i]} days):`}
                    lettersColor={colorMain}
                    numbersColor={colorSecondary}
                    mobileFontSize={mobileFontSize}
                    amount={unboundingAmountPool[i]}
                  />
                </div>
              );
            })
          }

        </>
      }


    </WidgetWrapper>
  );
};
